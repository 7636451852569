import React, { Component } from "react";
import axios from "axios";
import PNavbar from "../private-route/Nav";
import Breadcrumb from "react-bootstrap/Breadcrumb";
const $ = window.$;

export default class EditGoal extends Component {
  constructor(props) {
    super(props);

    this.onChangeGoalTitle = this.onChangeGoalTitle.bind(this);
    this.onChangeGoalDesc = this.onChangeGoalDesc.bind(this);
    this.onChangeGoalPeriod = this.onChangeGoalPeriod.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onDelete = this.onDelete.bind(this);

    this.state = {
      goal_title: "",
      goal_desc: "",
      goal_period: ""
    };
  }

  componentDidMount() {
    axios
      .get(
        process.env.REACT_APP_API_URL + "goals/" + this.props.match.params.id
      )
      .then(response => {
        this.setState({
          goal_title: response.data.goal_title,
          goal_desc: response.data.goal_desc,
          goal_period: response.data.goal_period
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  onChangeGoalTitle(e) {
    this.setState({
      goal_title: e.target.value
    });
  }

  onChangeGoalDesc(e) {
    this.setState({
      goal_desc: e.target.value
    });
  }

  onChangeGoalPeriod(e) {
    let valu = e.target.value;
    console.log(valu);
    if (!Number(valu)) {
      return;
    }

    this.setState({ goal_period: e.target.value });

    // this.setState({
    //   goal_period: e.target.value
    // });
  }
  onSubmit(e) {
    e.preventDefault();

    const obj = {
      goal_title: this.state.goal_title,
      goal_desc: this.state.goal_desc,
      goal_period: this.state.goal_period
    };

    axios
      .post(
        process.env.REACT_APP_API_URL +
          "goals/update/" +
          this.props.match.params.id,
        obj
      )
      .then(res => console.log(res.data));

    this.props.history.push("/goals");
  }

  onDeleteConfirm() {
    $("#exampleModal").modal("show");
  }

  onDelete() {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          "goals/remove/" +
          this.props.match.params.id
      )
      .then(console.log("Deleted"))
      .catch(err => console.log(err));
    $("#exampleModal").modal("toggle");
    this.props.history.push("/goals");
  }

  render() {
    return (
      <div className="container">
        <PNavbar />
        <div>
          <Breadcrumb>
            <Breadcrumb.Item href="/dash">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/goals">Goals</Breadcrumb.Item>
            <Breadcrumb.Item active>Goal</Breadcrumb.Item>
          </Breadcrumb>
          <h1 className="h1-home-header-main">Update Goal</h1>
          <div className="shadow-sm p-3 mb-2 bg-white rounded ">
            <form onSubmit={this.onSubmit}>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <label htmlFor="">Goal Name</label>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.goal_title}
                      placeholder={this.state.goal_title}
                      onChange={this.onChangeGoalTitle}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <label htmlFor="">Time Period (days)</label>
                  <div className="form-group">
                    <input
                      type="number"
                      className="form-control"
                      value={this.state.goal_period}
                      placeholder="Time Period"
                      onChange={this.onChangeGoalPeriod}
                    />
                  </div>
                  <span className="input-explain">
                    The recommended time period for completing a goal (44 days).
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <label htmlFor="">Goal Description</label>
                    <textarea
                      className="form-control"
                      onChange={this.onChangeGoalDesc}
                      value={this.state.goal_desc}
                      placeholder="Task description"
                      id="exampleFormControlTextarea1"
                      rows="3"
                    ></textarea>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12"></div>
              </div>

              <div className="form-group">
                <div className="form-group">
                  <input
                    type="submit"
                    value="Update"
                    className="btn btn-primary"
                    title="Update Goal"
                  />

                  <div style={{ float: "right" }}>
                    <img
                      onClick={this.onDeleteConfirm}
                      style={{ float: "right" }}
                      src="/images/delete.png"
                      className="icon-delete"
                      title="Delete Goal"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Are you sure?
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                You're going to delete Goal <b>{this.state.goal_title}</b>{" "}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.onDelete}
                >
                  I'm Sure
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
