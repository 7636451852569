import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navbar from "../layout/Navbar";
import axios from "axios";
import { encode } from "punycode";
var utils = require("../../utils/utils");
const base64url = require("base64-url");

class Confirm extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      user_email: "",
      user_join_date: "",
      user_img_url: "/images/avatar.png",
      jwtToken: "",
      _id: ""
    };

    const objUser = {
      confirmkey: base64url.decode(this.props.match.params.id).split("||")[0]
    };

    axios
      .post(
        process.env.REACT_APP_API_URL +
          "users/confirm/" +
          this.props.match.params.id,
        objUser
      )
      .then(res => console.log(res.data));
  }

  componentDidMount() {
    // axios
    //   .get(process.env.REACT_APP_API_URL + "users/me")
    //   .then(response => {
    //     this.setState({
    //       user_email: response.data.email,
    //       user_join_date: response.data.date,
    //       name: response.data.name,
    //       img_url: response.data.img_url,
    //       _id: response.data._id
    //     });
    //   })
    //   .catch(function(error) {
    //     console.log(error);
    //   });
  }

  onSubmit(e) {
    e.preventDefault();

    // var dateFormat = require("dateformat");
    // var nDate = dateFormat(this.state.task_duedate, "yyyy-mm-dd hh:MM:ss");

    const objUser = {
      confirmkey: toString(this.props.match.params.id).split("||")[0]
    };

    console.log("objUser:" + objUser);

    // axios
    //   .post(
    //     process.env.REACT_APP_API_URL +
    //       "users/update/" +
    //       toString(this.props.match.params.id).split("||")[1],
    //     objUser
    //   )
    //   .then(res => console.log(res.data));

    //this.props.history.push("/");
  }

  render() {
    return (
      <div className="container container-fluid">
        <Navbar />
        <div style={{ marginTop: 50 }}>
          <h1 className="container " style={{ textAlign: "center" }}>
            <div className="shadow-sm p-3 mb-2 bg-white rounded">
              Verification Succeeded!
              <br />
              <br />
              <a className="btn btn-success" href="/login">
                Back to Sign In
              </a>
            </div>
          </h1>
        </div>
      </div>
    );
  }
}

export default Confirm;
