import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
class Footer extends Component {
  render() {
    const { version, year, location } = this.props;
    const currentPath = location.pathname; // This gives you the current path
    return (
      <>
        {currentPath == "/comingsoon" ||
        currentPath == "/cs/tos" ||
        currentPath == "/cs/privacy" ? (
          <></>
        ) : (
          <footer className="footer mt-auto py-3">
            <div className="container">
              <div className="row">
                <div
                  className="col-xs-12 col-sm-6 col-lg-3 footer-text"
                  style={{ paddingBottom: 5 }}
                >
                  <b>Product</b>
                  <br />
                  <Link to="/about" className="footer-link">
                    How It Works?
                  </Link>
                </div>
                <div
                  className="col-xs-12 col-sm-6 col-lg-3 footer-text"
                  style={{ paddingBottom: 5 }}
                >
                  <b>Legal</b>
                  <br />
                  <Link to="/privacy" className="footer-link">
                    Privacy
                  </Link>
                  <br />
                  <Link to="/tos" className="footer-link">
                    Terms of Service
                  </Link>
                </div>
                <div
                  className="col-xs-12 col-sm-6 col-lg-3 footer-text"
                  style={{ paddingBottom: 5 }}
                >
                  <b>About</b>

                  <br />
                  <Link to="/careers" className="footer-link">
                    Careers
                  </Link>
                </div>
                <div
                  className="col-xs-12 col-sm-6 col-lg-3 footer-text"
                  style={{ paddingBottom: 5 }}
                >
                  <b>Support</b>
                  <br />
                  <Link to="/qa" className="footer-link">
                    Q&A
                  </Link>
                  <br />

                  <Link to="/contactus" className="footer-link">
                    Contact Us
                  </Link>
                </div>
              </div>

              <span className="footer-text">
                © {year} bealyo.com {currentPath}
              </span>
            </div>
          </footer>
        )}
      </>
    );
  }
}
export default withRouter(Footer);
