import React, { Component } from "react";
import axios from "axios";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import GaugeChart from "react-gauge-chart";
import { Chart } from "react-google-charts";
import { trackPromise } from "react-promise-tracker";
import PNavbar from "../private-route/Nav";
import Button from "react-bootstrap/Button";
import { Container, Link as LinkButton } from "react-floating-action-button";
import { Link } from "react-router-dom";
var utils = require("../../utils/utils");

const $ = window.$;

const TaskObject = (props) => (
  <div>
    {" "}
    <Link to={"/task/" + props.todo._id}>{props.todo.todo_title}</Link>
  </div>
);
const GoalObject = (props) => (
  <div>
    {" "}
    <Link to={"/goal/" + props.todo._id}>{props.todo.goal_title}</Link>
  </div>
);

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      goals: [],
      todos: [],
      tasks_sum_value: 0,
      tasks_sum_value_reported: 0,
    };
  }

  //#region  todoList
  todoList() {
    return this.state.todos.map(function (currentTodo, i) {
      return <TaskObject todo={currentTodo} key={i} />;
    });
  }
  //#endregion

  //#region  goalList
  goalList() {
    return this.state.goals.map(function (currentTodo, i) {
      return <GoalObject todo={currentTodo} key={i} />;
    });
  }
  //#endregion

  componentDidMount() {
    console.log(process.env.REACT_APP_API_URL + "goals/");
    //let jwtToken = localStorage.getItem("jwtToken").replace("Bearer ", "");
    //console.log("jwtToken:" + jwtToken);
    trackPromise(
      axios
        .get(process.env.REACT_APP_API_URL + "goals/")
        .then((response) => {
          this.setState({ goals: response.data });
          if (response.data.length == "0") {
            $("#exampleModal").modal("show");

            //setTimeout($("#exampleModal").modal("hide"), 10000);

            // $("#exampleModal")
            //   .fadeTo(2000, 500)
            //   .slideUp(500, function() {
            //     $("#exampleModal").slideUp(500);
            //   });
          }
        })
        .catch(function (error) {
          console.log(error);
        })
    );

    trackPromise(
      axios
        .get(process.env.REACT_APP_API_URL + "todos/")
        .then((response) => {
          this.setState({ todos: response.data });

          response.data.forEach((element) => {
            //console.log(element.task_value);
            //console.log(element.task_value_reported);

            if (
              element.task_value != "" &&
              element.task_value !== undefined &&
              element.task_value !== "NaN" &&
              element.task_value_reported != "" &&
              element.task_value_reported !== undefined &&
              element.task_value_reported !== "NaN"
            ) {
              this.setState({
                tasks_sum_value:
                  Number.parseInt(this.state.tasks_sum_value) +
                  Number.parseInt(element.task_value),
              });
              // console.log(
              //   "element.task_value_reported" + element.task_value_reported
              // );
              this.setState({
                tasks_sum_value_reported:
                  Number.parseInt(this.state.tasks_sum_value_reported) +
                  Number.parseInt(element.task_value_reported),
              });
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        })
    );
  }

  setNewGoal(e) {
    $("#exampleModal").modal("toggle");
    window.location = "/goalcats";
    //this.props.history.push("/goalcats");
  }

  render() {
    setTimeout(() => {
      $("#exampleModal").modal("hide");
    }, 10000);

    // setTimeout(() => {
    //   $("#alertModal").className = "hide";
    // }, 5000);

    var task_percentage = 0;

    if (
      this.state.tasks_sum_value != "" &&
      this.state.tasks_sum_value !== undefined &&
      this.state.tasks_sum_value !== "NaN" &&
      this.state.tasks_sum_value_reported != "" &&
      this.state.tasks_sum_value_reported !== undefined &&
      this.state.tasks_sum_value_reported !== "NaN"
    ) {
      task_percentage =
        100 *
        (this.state.tasks_sum_value_reported / this.state.tasks_sum_value);
    }
    task_percentage = Number(task_percentage.toFixed(1));

    var goal_percentage = 0;
    return (
      <div className="container container-fluid">
        <PNavbar />

        <div style={{ marginTop: 20 }}>
          <h1 className="h1-home-header-dash">
            Welcome {utils.userDetails().name}!
          </h1>
        </div>

        {/* Alert message
         */}
        {/* <div
          className="alert alert-warning alert-dismissible fade show"
          role="alert"
          id="alertModal"
        >
          <strong>Well Done! {utils.userDetails().name}</strong>
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div> */}

        {/* <button
          type="button"
          className="btn btn-primary"
          data-toggle="modal"
          data-target="#exampleModal"
        >
          Launch demo modal
        </button> */}

        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Welcome to Bealyo!
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                Life can feel overwhelming, but it doesn’t have to. bealyo lets
                you keep track of everything in one place, so you can get it all
                done and enjoy more peace of mind along the way.
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <Link
                  onClick={this.setNewGoal}
                  to=""
                  className="btn btn-primary "
                >
                  Set your first Goal!
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div
              className="shadow-sm p-3 mb-2 bg-white rounded "
              style={{ minHeight: 120 }}
            >
              <div className="row">
                <div className="dash-top-box">
                  <div className="float-left home-dash-bar">
                    <CircularProgressbar
                      value={goal_percentage}
                      text={`${goal_percentage}%`}
                    />
                  </div>
                  {/* <div className="float-left">
                    <img src="/images/goal.png" style={{ width: 90 }} />
                  </div> */}
                  <div style={{ float: "left", width: 0 }}>
                    {/* <Chart
                      width={120}
                      height={90}
                      chartType="Gauge"
                      loader={<div>Loading Chart</div>}
                      data={[
                        ["Label", "Value"],
                        ["Goals", this.state.goalchart]
                      ]}
                      options={{
                        greenFrom: 90,
                        greenTo: 100,
                        yellowFrom: 75,
                        yellowTo: 90,
                        minorTicks: 1,
                        animation: {
                          duration: 800
                        }
                      }}
                      rootProps={{ "data-testid": "1" }}
                    /> */}
                  </div>

                  <div
                    className="float-right"
                    style={{ minWidth: 70, textAlign: "center" }}
                  >
                    <div>Goals</div>
                    <div style={{ fontSize: 40 }}>
                      {this.goalList().length > 0 ? (
                        <Link to="/goals" className="">
                          {this.goalList().length}
                        </Link>
                      ) : (
                        <span>0</span>
                      )}
                    </div>
                  </div>
                  <div
                    className="float-right"
                    style={{ minWidth: 70, textAlign: "center" }}
                  >
                    {/* <div>Success</div>
                    <div style={{ fontSize: 40 }}>
                      {this.goalList().length}
                      <span style={{ fontSize: 30 }}>%</span>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="dash-top-box">
                  <div className="dropdown-divider"></div>
                  <div>
                    <img src="images/dash-new.png" className="dash-new-icon" />{" "}
                  </div>

                  <Link className="dash-new-link" to="/goalcats">
                    Create a New Goal!
                  </Link>
                  {this.goalList().length ? (
                    <Link
                      className="dash-new-link"
                      to="/goals"
                      //className="float-right"
                    >
                      <img
                        src="images/dash-more.png"
                        className="dash-new-icon"
                      />
                    </Link>
                  ) : (
                    <span></span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div
              className=" shadow-sm p-3 mb-2 bg-white rounded"
              style={{ minHeight: 120 }}
            >
              <div className="row">
                <div className="dash-top-box">
                  <div className="float-left home-dash-bar">
                    <CircularProgressbar
                      value={task_percentage}
                      text={`${task_percentage}%`}
                    />
                  </div>
                  <div style={{ float: "left", width: 0 }}></div>
                  <div
                    className="float-right"
                    style={{ minWidth: 70, textAlign: "center" }}
                  >
                    <div>Tasks</div>
                    <div style={{ fontSize: 40 }}>
                      {this.todoList().length > 0 ? (
                        <Link to="/" className="">
                          {this.todoList().length}
                        </Link>
                      ) : (
                        <span>0</span>
                      )}
                    </div>
                  </div>
                  <div
                    className="float-right"
                    style={{ minWidth: 70, textAlign: "center" }}
                  >
                    {/* <div>Success</div>
                    <div style={{ fontSize: 40 }}>
                      {this.todoList().length}
                      <span style={{ fontSize: 30 }}>%</span>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="dash-top-box">
                  <div className="dropdown-divider"></div>
                  <div>
                    <img src="images/dash-new.png" className="dash-new-icon" />{" "}
                  </div>
                  <Link className="dash-new-link" to="/create">
                    Create a New Task!
                  </Link>
                  {/* {this.todoList()} */}
                  {this.todoList().length ? (
                    <Link
                      className="dash-new-link"
                      to="/"
                      //className="float-right"
                    >
                      <img
                        src="images/dash-more.png"
                        className="dash-new-icon"
                      />
                    </Link>
                  ) : (
                    <span></span>
                  )}
                </div>
              </div>

              {/* <div className="float-left">
                {this.todoList().length ? (
                  <Link to="/">
                    <b>Tasks</b>
                  </Link>
                ) : (
                  <b>Tasks</b>
                )}
              </div>

              {this.todoList().length ? (
                this.todoList()
              ) : (
                <div>You haven't created any tasks yet</div>
              )} */}
            </div>
          </div>
        </div>

        <Container>
          <LinkButton
            href="/goalcats"
            tooltip="Create Goal"
            icon="far fa-sticky-note"
          >
            {" "}
            <b className="plus-button"> G</b>
          </LinkButton>
          <LinkButton
            href="/create"
            tooltip="Create Task"
            icon="fas fa-user-plus"
            className="fab-item btn btn-link btn-lg text-white"
          >
            {" "}
            <b className="plus-button"> T</b>
          </LinkButton>
          {/* <Button
            tooltip="The big plus button!"
            href="/create"
            icon="fas fa-plus"
            rotate={true}
            onClick={() => alert("FAB Rocks!")}
          /> */}
          <LinkButton
            href="#"
            icon="far fa-sticky-note"
            className="plus-button-bg"
          >
            <b className="plus-button-plus"> +</b>
          </LinkButton>
        </Container>
      </div>
    );
  }
}

export default Home;
