import React, { Component } from "react";
import PNavbar from "../private-route/Nav";
import axios from "axios";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";

const UserObjectDiv = props => (
  <div className="col-lg-6 col-md-12" key={props.key}>
    <div
      className="shadow-sm p-3 mb-2 bg-white rounded"
      style={{ height: 140 }}
    >
      <div className="row">
        <div className="col-4 col-md-3">
          {" "}
          <img
            className="avatar-circle-small"
            src={
              props.todo.img_url === ""
                ? "/images/icons/avatar.jpg"
                : props.todo.img_url
            }
          />
        </div>
        <div className="col-8 col-md-9">
          <div className="row">
            <div className="col-12 col-md-6">
              <Link to={"/users/" + props.todo._id}>
                {" "}
                <b>{props.todo.name}</b>
              </Link>
              <br />
              {props.todo.email}
            </div>
            <div className="col-12 col-md-6">
              <b>{props.totalScore}</b> Points <br />
              <b>
                {props.todo.goals_docs != undefined
                  ? props.todo.goals_docs.length
                  : 0}
              </b>{" "}
              Goals <br />
              <b>
                {props.todo.tasks_docs != undefined
                  ? props.todo.tasks_docs.length
                  : 0}
              </b>{" "}
              Tasks
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

class Community extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user_email: "",
      user_join_date: "",
      img_url: "/images/avatar.png",
      jwtToken: "",
      _id: "",
      items: []
    };
  }

  //#region  componentDidMount
  componentDidMount() {
    this.getUsers();
  }
  //#endregion

  //#region getUsers
  getUsers() {
    var apiurl = "users/users";
    trackPromise(
      axios
        .post(process.env.REACT_APP_API_URL + apiurl)
        .then(response => {
          this.setState({ todos: response.data });
          this.setState({
            items: response.data
          });
        })
        .catch(function(error) {
          console.log(error);
        })
    );
  }
  //#endregion

  userList() {
    //console.log(this.state.items);
    return this.state.items.map(function(currentTodo, i) {
      let totalScore = 0;
      if (currentTodo.scores_docs != undefined) {
        for (var i = 0; i < currentTodo.scores_docs.length; i++) {
          totalScore = totalScore + parseInt(currentTodo.scores_docs[i].score);
        }
      }

      //console.log(currentTodo.scores_docs.length);
      return (
        <UserObjectDiv todo={currentTodo} key={i} totalScore={totalScore} />
      );
    });
    //return <div>dd</div>;
  }

  render() {
    return (
      <div className="container">
        <PNavbar />
        <div>
          <Breadcrumb>
            <Breadcrumb.Item href="/dash">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Community</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div>
          <h1 className="h1-home-header-main">Community</h1>
        </div>
        <div className="row">{this.userList()}</div>
      </div>
    );
  }
}

export default Community;
