import React, { Component } from "react";
import Navbar from "../layout/Navbar";
import Breadcrumb from "react-bootstrap/Breadcrumb";

class About extends Component {
  render() {
    return (
      <div className="container">
        <Navbar />
        <div>
          <Breadcrumb>
            <Breadcrumb.Item href="/dash">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>How It Works?</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div>
          <h1 className="h1-home-header-main">How It Works?</h1>
        </div>

        <div className="shadow-sm p-3 mb-2 bg-white rounded">
          {/* <p className="lead">
            Bealyo gives you the confidence that everything’s organized and
            accounted for, so you can make progress on the things that are
            important to you.
          </p> */}

          <div className="row">
            <div className="col-xs-12 col-md-4 how-column-img">
              <img src="/images/how2.png" />
            </div>
            <div
              className="col-xs-12 col-md-8 how-column"
              style={{ paddingTop: 20 }}
            >
              <p className="lead">
                Bealyo gives you the confidence that everything’s organized and
                accounted for, so you can make progress on the things that are
                important to you.
              </p>
              <p className="lead">We're using AI and ML for improve ...</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
