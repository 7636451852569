import React from "react";
import { Link } from "react-router-dom";
import "../menu/menu.css"; // Assuming you have a CSS file for styling
import {
  FaFacebook,
  FaTwitter,
  FaWhatsapp,
  FaTelegram,
  FaEnvelope,
} from "react-icons/fa";

import {
  EmailShareButton,
  FacebookShareButton,
  GabShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";

import ReactGA from "react-ga4";

// Initialize GA4
ReactGA.initialize("G-3VKRXQ973F");

const comingsoonm = () => {
  const shareUrl = "https://www.bealyo.com"; // Replace with your actual URL
  //const title = "Want to be part of something amazing? Bealyo is coming soon! Sign up to be notified and get exclusive access to early features and discounts.";
  const logoImage = process.env.PUBLIC_URL + "/images/logo/fulllogo-1.png";
  const title =
    "Get ready for a life-changing app! Bealyo is an AI-powered platform designed to help you reach your full potential. Sign up now to be notified when we launch.";
  const emailSubject = "Bealyo is Coming Soon!";
  const emailBody = `${title} Check it out at ${shareUrl}`;

  const handleShareButtonClick = (platform) => {
    console.log(`Clicked ${platform} Share Button`);
    ReactGA.event({
      category: "Share Button",
      action: `Clicked ${platform} Share Button`,
    });
  };

  const handleMenuLinkClick = (linkName) => {
    ReactGA.event({
      category: "Menu Link",
      action: `Clicked ${linkName} Link`,
    });
  };

  const handleClick = () => {
    const logoImageUrl = process.env.PUBLIC_URL + "/images/logo/fulllogo-1.png"; // Replace with the actual URL of your logo image
    //const emailBodyWithLogo = `${emailBody}<br><br><img src="${logoImageUrl}" alt="Logo" />`;
    const mailtoLink = `mailto:?subject=${encodeURIComponent(
      emailSubject
    )}&body=${encodeURIComponent(emailBody)}`;
    window.location.href = mailtoLink;
    handleShareButtonClick("Email");
  };

  return (
    <>
      <div className="share-buttons">
        <FacebookShareButton
          url={shareUrl}
          quote={title}
          image={logoImage}
          className="share-button"
          onClick={() => handleShareButtonClick("Facebook")}
        >
          <FaFacebook className="facebook-icon" />
        </FacebookShareButton>
        <TwitterShareButton
          url={shareUrl}
          title={title}
          image={logoImage}
          className="share-button"
          onClick={() => handleShareButtonClick("Twitter")}
        >
          <FaTwitter className="twitter-icon" />
        </TwitterShareButton>
        <WhatsappShareButton
          url={shareUrl}
          title={title}
          image={logoImage}
          className="share-button"
          onClick={() => handleShareButtonClick("Whatsapp")}
        >
          <FaWhatsapp className="whatsapp-icon" />
        </WhatsappShareButton>
        <TelegramShareButton
          url={shareUrl}
          title={title}
          image={logoImage}
          className="share-button"
          onClick={() => handleShareButtonClick("Telegram")}
        >
          <FaTelegram className="telegram-icon" />
        </TelegramShareButton>
        <button className="share-button" onClick={handleClick}>
          <FaEnvelope className="email-icon" />
        </button>
      </div>
      <div className="menu">
        <div className="menu-item">
          <span className="menu-text">© 2025 bealyo</span>
        </div>
        <span className="menu-separator">/</span>
        <div className="menu-item">
          <Link
            to="/cs/privacy"
            onClick={() => handleMenuLinkClick("Privacy Policy")}
            className="menu-link"
          >
            Privacy Policy
          </Link>
        </div>
        <span className="menu-separator">/</span>
        <div className="menu-item">
          <Link
            to="/cs/tos"
            onClick={() => handleMenuLinkClick("Terms of Service")}
            className="menu-link"
          >
            Terms of Service
          </Link>
        </div>
      </div>
    </>
  );
};

export default comingsoonm;
