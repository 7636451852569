import React, { Component } from "react";
import Navbar from "../layout/Navbar";
import Breadcrumb from "react-bootstrap/Breadcrumb";

class Careers extends Component {
  render() {
    return (
      <div className="container">
        <Navbar />
        <div>
          <Breadcrumb>
            <Breadcrumb.Item href="/dash">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Careers</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div>
          <h1 className="h1-home-header-main">Careers</h1>
        </div>

        <div className="shadow-sm p-3 mb-2 bg-white rounded lead">
          <b>Front End Engineer</b>

          <p className="careers">
            We have enormous and engaging technical challenges. We are designing
            an API that can drop easily into any one of dozens of ecommerce
            platforms, proprietary and off-the-shelf, hosted and on-site. We
            need to verify online identity and predict fraud while providing a
            beautiful and frictionless consumer experience, and mine user and
            financial information to approve and price credit, all in real time.
            And we are using technology to explore new approaches to consumer
            credit marketplaces that have never been attempted before.
          </p>
          <p className="careers">
            Come learn what we’re all about at www.bealyo.com.
          </p>
          <p className="careers">
            We are looking to hire a Front End Engineer to join the team in
            building our product. This engineer will:
          </p>
          <p className="careers">
            work with the rest of the engineering team to deliver new
            functionality to face merchants, consumers, investors, and internal
            users create beautiful user interfaces and elegant user experiences
            be on the front lines responding to customer issues and ensuring
            smooth integrations
          </p>
          <b style={{ fontSize: 14 }}>Requirements</b>
          <br />
          <p className="careers">
            <ul>
              <li>
                Strong knowledge of Javascript and front-end frameworks (we use
                React, but would happily speak with people who have deep
                experience with frameworks like Angular or Backbone)
              </li>
              <li>
                Experience developing rich, reliable, cross-browser-compatible
                web experiences
              </li>
              <li>
                Experience designing elegant, expressive client-side Javascript
                APIs
              </li>
              <li>
                Experience creating robust UI automation testing as part of the
                development process
              </li>
              <li>
                An ability to translate designs into pixel-perfect HTML and CSS
              </li>
              <li>A passion for engineering and technology </li>
              <li>Preferred</li>
            </ul>
          </p>
          <p className="careers">
            Some background in visual design Knowledge of financial products No
            fear of the back end. Our system is built with Go and PostgreSQL,
            but we’re also happy to speak with those that have experience with
            other backend languages, including JVM languages like Java or Scala;
            Python; or C++ Knowledge of e-commerce platforms, like Magento,
            Shopify, Demandware, or others If this is you, submit a cover letter
            and resume to apply!
          </p>
          <br />
          <br />
          <b>Senior Software Developer</b>
          <br />
          <p className="careers">
            We have enormous and engaging technical challenges. We are designing
            an API that can drop easily into any one of dozens of ecommerce
            platforms, proprietary and off-the-shelf, hosted and on-site. We
            need to verify online identity and predict fraud while providing a
            beautiful and frictionless consumer experience, and mine user and
            financial information to approve and price credit, all in real time.
            And we are using technology to explore new approaches to consumer
            credit marketplaces that have never been attempted before.
          </p>
          <p className="careers">
            Come learn what we’re all about at www.bealyo.com.
          </p>
          <p className="careers">
            We are looking to hire a Front End Engineer to join the team in
            building our product. This engineer will:
          </p>
          <p className="careers">
            work with the rest of the engineering team to deliver new
            functionality to face merchants, consumers, investors, and internal
            users create beautiful user interfaces and elegant user experiences
            be on the front lines responding to customer issues and ensuring
            smooth integrations
          </p>
          <b style={{ fontSize: 14 }}>Requirements</b>
          <br />
          <p className="careers">
            <ul>
              <li>
                Strong knowledge of Javascript and front-end frameworks (we use
                React, but would happily speak with people who have deep
                experience with frameworks like Angular or Backbone)
              </li>
              <li>
                Experience developing rich, reliable, cross-browser-compatible
                web experiences
              </li>
              <li>
                Experience designing elegant, expressive client-side Javascript
                APIs
              </li>
              <li>
                Experience creating robust UI automation testing as part of the
                development process
              </li>
              <li>
                An ability to translate designs into pixel-perfect HTML and CSS
              </li>
              <li>A passion for engineering and technology </li>
              <li>Preferred</li>
            </ul>
          </p>
          <p className="careers">
            Some background in visual design Knowledge of financial products No
            fear of the back end. Our system is built with Go and PostgreSQL,
            but we’re also happy to speak with those that have experience with
            other backend languages, including JVM languages like Java or Scala;
            Python; or C++ Knowledge of e-commerce platforms, like Magento,
            Shopify, Demandware, or others If this is you, submit a cover letter
            and resume to apply!
          </p>
        </div>
      </div>
    );
  }
}

export default Careers;
