import React, { Component } from "react";
import PNavbar from "../private-route/Nav";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { logoutUser } from "../../actions/authActions";
import { connect } from "react-redux";
import axios from "axios";
import Moment from "react-moment";
import "moment-timezone";
import Breadcrumb from "react-bootstrap/Breadcrumb";
var utils = require("../../utils/utils");

class Settings extends Component {
  constructor(props) {
    super(props);

    this.onChangeNotiPush = this.onChangeNotiPush.bind(this);
    this.onChangeNotiEmail = this.onChangeNotiEmail.bind(this);

    this.state = {
      user_email: "",
      user_join_date: "",
      user_img_url: "/images/avatar.png",
      jwtToken: "",
      _id: "",
      notipush: "",
      notiemail: "",
      notichecked: true,
      emailchecked: true
    };
  }

  handleFileUpload = event => {
    console.log("handleFileUpload " + event.target.files);
    this.setState({ file: event.target.files });

    var fileName = event.target.files[0].name;

    if (event.target.files != undefined) {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);

      axios
        .post(process.env.REACT_APP_API_URL + "files/test-upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          // handle your response;
          console.log(process.env.REACT_APP_IMG_FOLDER + response.data.Key);
          this.setState({
            img_url: process.env.REACT_APP_IMG_FOLDER + response.data.Key
          });

          console.log(
            process.env.REACT_APP_API_URL + "users/update/" + this.state._id
          );

          const objUser = {
            img_url: this.state.img_url
          };

          axios
            .post(
              process.env.REACT_APP_API_URL + "users/update/" + this.state._id,
              objUser
            )
            .then(res => console.log(res.data));

          document.getElementsByClassName("avatar")[0].innerHTML =
            process.env.REACT_APP_IMG_FOLDER + response.data.Key;

          //console.log(this.state.upload_status);
        })
        .catch(error => {
          // handle your error
        });
    }
    //this.setState({ todo_img_url: event.target.files[0].Key });
  };

  componentDidMount() {
    // axios
    //   .get(process.env.REACT_APP_API_URL + "users/me")
    //   .then(response => {
    //     this.setState({
    //       user_email: response.data.email,
    //       user_join_date: response.data.date,
    //       name: response.data.name,
    //       img_url: response.data.img_url,
    //       _id: response.data._id
    //     });
    //   })
    //   .catch(function(error) {
    //     console.log(error);
    //   });
  }

  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
    //this.closeNav();
  };

  //#region onChangeTodoPriority
  onChangeNotiPush(e) {
    console.log("onChangeNotiPush");
    this.setState({
      notipush: e.target.value
    });
    this.setState({ emailchecked: !this.state.emailchecked }); //look at the !NOT sign

    //e.target.checked = false;
  }
  //#endregion

  //#region onChangeTodoPriority
  onChangeNotiEmail(e) {
    console.log("onChangeNotiEmail");
    this.setState({
      notiemail: e.target.value
    });
    this.setState({ notichecked: !this.state.notichecked }); //look at the !NOT sign

    //e.target.checked = false;
  }
  //#endregion

  render() {
    return (
      <div className="container">
        <PNavbar />

        <Breadcrumb>
          <Breadcrumb.Item href="/dash">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Settings</Breadcrumb.Item>
        </Breadcrumb>
        <div>
          <h1 className="h1-home-header-main float-left">Settings</h1>
        </div>
        <br />

        <div
          className="shadow-sm p-3 mb-2 bg-white rounded"
          style={{ marginTop: 12 }}
        >
          <div className="row">
            <div className="col-12">
              <b>Notifications</b>
            </div>
            <div className="col-6">
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customSwitch1"
                  checked={this.state.notichecked}
                  onChange={this.onChangeNotiEmail}
                />
                <label className="custom-control-label" htmlFor="customSwitch1">
                  Send Emails
                </label>
              </div>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customSwitch2"
                  checked={this.state.emailchecked}
                  onChange={this.onChangeNotiPush}
                />
                <label className="custom-control-label" htmlFor="customSwitch2">
                  Push Notification
                </label>
              </div>

              {/* <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  disabled
                  id="customSwitch2"
                />
                <label class="custom-control-label" for="customSwitch2">
                  Disabled switch element
                </label>
              </div> */}
            </div>
          </div>
          {/* <b>Application:</b>
          <br />
          MODE: {process.env.NODE_ENV}
          <br />
          REACT_APP_VERSION:{process.env.REACT_APP_VERSION}
          <br />
          REACT_APP_WEBSITE_NAME:{process.env.REACT_APP_WEBSITE_NAME}
          <br />
          Clock Format: 12H / 24H
          <br />
          <br /> */}
        </div>
        <div className="shadow-sm p-3 mb-2 bg-white rounded">
          <div className="row">
            <div className="col-12">
              <b>Profile</b>
            </div>
            <div className="col-6">
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="customSwitch1"
                  checked={this.state.notichecked}
                  onChange={this.onChangeNotiEmail}
                />
                <label className="custom-control-label" htmlFor="customSwitch1">
                  Public Profile
                </label>
              </div>
            </div>
          </div>
        </div>
        <Link to="/" className="" onClick={this.onLogoutClick}>
          Logout
        </Link>
      </div>
    );
  }
}

Settings.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { logoutUser })(Settings);
