import React, { Component } from "react";
import Navbar from "../layout/Navbar";
import Breadcrumb from "react-bootstrap/Breadcrumb";

class Privacy extends Component {
  render() {
    return (
      <div className="container">
        <Navbar />
        <div>
          <Breadcrumb>
            <Breadcrumb.Item href="/dash">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Privacy</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div>
          <h1 className="h1-home-header-main">Bealyo Privacy</h1>
        </div>

        <div className="shadow-sm p-3 mb-2 bg-white rounded lead">
          <b>Information</b>
          <p>
            When registering for Bealyo we ask for information such as your name
            and email address. Your information is only used internally and
            won't be shared with others.
          </p>
          <p>
            Bealyo collects the email addresses of those who communicate with us
            via email, and information submitted through voluntary activities
            such as site registrations or participation in surveys. Bealyo also
            collects aggregated, anonymous user data regarding app usage. The
            user data we collect is used to improve Bealyo and the quality of
            our service. We only collect personal data that is required to
            provide our services, and we only store it insofar that it is
            necessary to deliver these services.
          </p>
          <p>
            Bealyo uses third party vendors and hosting partners to provide the
            necessary hardware, software, networking, storage, and related
            technology required to run Bealyo. Although Bealyo owns the code,
            databases, and all rights to the Bealyo application, you retain all
            rights to your data. We will never share your personal data with a
            3rd party without your prior authorization, and we will never sell
            data to 3rd parties.
          </p>

          <b>Cookies</b>
          <p>
            We use cookies to store visitors preferences, customize Web page
            content based on visitors browser type or other information that the
            visitor sends. Cookies are required to use Bealyo.
          </p>

          <b>Security</b>
          <p>
            Bealyo takes practical and reasonable steps to protect Personal
            Information from unauthorized or accidental access, disclosure,
            misuse or processing, or from alteration, destruction, or loss. All
            data and information transmitted with Service is secured by SSL
            protocol.
          </p>

          <b>Changes to Privacy Policy</b>
          <p>
            If our information practices change at some time in the future we
            will post the policy changes to our Web site to notify you of these
            changes and we will use for these new purposes only data collected
            from the time of the policy change forward. If you are concerned
            about how your information is used, you should check back at our Web
            site periodically.
          </p>
          <p>12 Apr 2020 © Copyright 2018 Bealyo, Inc. All rights reserved.</p>
        </div>
      </div>
    );
  }
}

export default Privacy;
