import React, { Component } from "react";
import axios from "axios";

import PNavbar from "../private-route/Nav";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { trackPromise } from "react-promise-tracker";
const $ = window.$;

export default class EditTodo extends Component {
  constructor(props) {
    super(props);

    this.onChangeTodoDescription = this.onChangeTodoDescription.bind(this);
    this.onChangeTodoTitle = this.onChangeTodoTitle.bind(this);
    this.onChangeTodoResponsible = this.onChangeTodoResponsible.bind(this);
    this.onChangeTodoPriority = this.onChangeTodoPriority.bind(this);
    this.onChangeTodoCompleted = this.onChangeTodoCompleted.bind(this);
    this.onChangeTaskGoalId = this.onChangeTaskGoalId.bind(this);
    this.onChangeTaskValue = this.onChangeTaskValue.bind(this);

    this.onSubmit = this.onSubmit.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onDeleteImage = this.onDeleteImage.bind(this);

    this.state = {
      todo_description: "",
      todo_title: "",
      todo_responsible: "",
      todo_priority: "",
      todo_completed: false,
      todo_img_url: "",
      upload_status: "",
      task_value: "",
      task_duedate: new Date(),
      startDate: new Date(),
      task_goalid: "",
      arrayOfData: [],
    };

    this.getGoals();
  }

  //#region componentDidMount
  componentDidMount() {
    axios
      .get(
        process.env.REACT_APP_API_URL + "todos/" + this.props.match.params.id
      )
      .then((response) => {
        var dateFormat = require("dateformat");
        // var nDate = dateFormat(
        //   response.data.task_duedate,
        //   "yyyy-mm-dd hh:MM:ss"
        // );

        var nDate = new Date(response.data.task_duedate.replace(/-/g, "/"));

        this.setState({
          todo_description: response.data.todo_description,
          todo_title: response.data.todo_title,
          todo_responsible: response.data.todo_responsible,
          todo_priority: response.data.todo_priority,
          todo_img_url: response.data.todo_img_url,
          todo_completed: response.data.todo_completed,
          task_value: response.data.task_value,
          task_duedate: nDate,
          task_goalid: response.data.task_goalid,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  //#endregion

  //#region getGoals()
  getGoals() {
    // Get goals
    trackPromise(
      axios
        .get(process.env.REACT_APP_API_URL + "goals/")
        .then((response) => {
          this.setState({ arrayOfData: response.data });
          //console.log("response.data - " + response.data);
        })
        .catch(function (error) {
          console.log(error);
        })
    );
  }
  //#endregion

  onChangeTodoDescription(e) {
    this.setState({
      todo_description: e.target.value,
    });
  }

  onChangeTodoTitle(e) {
    this.setState({
      todo_title: e.target.value,
    });
  }
  onChangeTodoResponsible(e) {
    this.setState({
      todo_responsible: e.target.value,
    });
  }

  onChangeTodoPriority(e) {
    this.setState({
      todo_priority: e.target.value,
    });
  }

  onChangeTodoCompleted(e) {
    //console.log(!this.state.todo_completed);
    this.setState({
      todo_completed: !this.state.todo_completed,
    });

    if (!this.state.todo_completed) $("#taskComplete").modal("show");

    const obj = {
      todo_completed: !this.state.todo_completed,
    };

    console.log(obj);

    axios
      .post(
        process.env.REACT_APP_API_URL +
          "todos/completed/" +
          this.props.match.params.id,
        obj
      )
      .then((res) => console.log(res.data))
      .catch((error) => {
        console.log("ERRORRRR" + error);
      });

    setTimeout(() => {
      $("#taskComplete").modal("hide");
    }, 10000);
  }

  handleFileUpload = (event) => {
    this.setState({
      upload_status: 1,
    });
    console.log(
      "Edit-handleFileUpload " +
        JSON.stringify(event.target.files) +
        " event.target.files.lenght[0]:" +
        event.target.files.length
    );
    this.setState({ file: event.target.files });

    var fileName = event.target.files[0].name;
    console.log("fileName" + fileName);
    console.log(document.getElementsByClassName("custom-file-label"));
    document.getElementsByClassName("custom-file-label")[0].innerHTML =
      fileName;

    if (event.target.files != undefined) {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);

      axios
        .post(process.env.REACT_APP_API_URL + "files/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // handle your response;
          console.log(response.data);
          console.log(
            // "https://bealyo-upload-files.s3.us-east-2.amazonaws.com/" +
            response.data.Key
          );
          this.setState({
            todo_img_url:
              // "https://bealyo-upload-files.s3.us-east-2.amazonaws.com/" +
              response.data.Key,
          });
          this.setState({
            upload_status: 2,
          });

          console.log(this.state.upload_status);
        })
        .catch((error) => {
          // handle your error
          console.log(error.response);
        });
    }
    //this.setState({ todo_img_url: event.target.files[0].Key });
  };

  onSubmit(e) {
    e.preventDefault();

    var dateFormat = require("dateformat");
    var nDate = dateFormat(this.state.task_duedate, "yyyy-mm-dd hh:MM:ss");

    const obj = {
      todo_description: this.state.todo_description,
      todo_title: this.state.todo_title,
      todo_responsible: this.state.todo_responsible,
      todo_priority: this.state.todo_priority,
      todo_img_url: this.state.todo_img_url,
      todo_completed: this.state.todo_completed,
      task_value: this.state.task_value,
      task_duedate: nDate,
      task_goalid: this.state.task_goalid,
    };

    axios
      .post(
        process.env.REACT_APP_API_URL +
          "todos/update/" +
          this.props.match.params.id,
        obj
      )
      .then((res) => console.log(res.data));

    if (this.state.task_goalid != "") {
      this.props.history.push("/goal/" + this.state.task_goalid);
    } else {
      this.props.history.push("/");
    }

    //this.props.history.push("/");
  }

  //#region  onDelete
  onDelete() {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          "todos/remove/" +
          this.props.match.params.id
      )
      .then(console.log("Deleted"))
      .catch((err) => console.log(err));

    if (this.state.task_goalid != "" && this.state.task_goalid != undefined) {
      this.props.history.push("/goal/" + this.state.task_goalid);
    } else {
      this.props.history.push("/");
    }
    $("#exampleModal").modal("toggle");
    //this.props.history.push("/");
  }
  //#endregion

  onChangeTaskValue(e) {
    console.log("onChangeTaskValue");
    this.setState({
      task_value: e.target.value,
    });
  }

  onChangeTaskDueDate = (date) => {
    console.log("onChangeTaskDueDate");

    this.setState({
      startDate: date,
    });

    this.setState({
      task_duedate: date,
    });

    console.log(date);
  };

  onChangeTaskGoalId(e) {
    console.log("onChangeTaskGoalId");
    this.setState({
      task_goalid: e.target.value,
    });
  }

  onDeleteImage() {
    const imgObj = {
      todo_img_url: this.state.todo_img_url,
    };

    console.log(this.state.todo_img_url);
    axios
      .post(
        process.env.REACT_APP_API_URL +
          "files/delete/" +
          this.props.match.params.id,
        imgObj
      )
      .then(
        console.log("Image Deleted"),
        this.setState({
          todo_img_url: "",
        })
      )
      .catch((err) => console.log(err));
    this.setState({ upload_status: "" });
    document.getElementsByClassName("custom-file-label")[0].innerHTML =
      "Update Image";

    // this.props.history.push("/");
  }

  //On the change event for the select box pass the selected value back to the parent
  handleChange = (event) => {
    let selectedValue = event.target.value;
    console.log(selectedValue);
    //this.onSelectChange(selectedValue);
    this.setState({
      task_goalid: selectedValue,
    });
  };

  onDeleteConfirm() {
    $("#exampleModal").modal("show");
  }

  render() {
    let arrayOfData = this.state.arrayOfData;

    let goalOptions = arrayOfData.map((data) => (
      <option key={data._id} value={data._id}>
        {data.goal_title}
      </option>
    ));

    return (
      <div className="container">
        <PNavbar />

        <Breadcrumb>
          <Breadcrumb.Item href="/dash">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/">Tasks</Breadcrumb.Item>
          <Breadcrumb.Item active>Edit Task</Breadcrumb.Item>
        </Breadcrumb>
        <div className="row">
          <div className="col-6">
            <h3>Edit Task</h3>
          </div>
          <div className="col-6 float-right">
            <div className=" complete-btn">
              {this.state.todo_completed ? (
                <input
                  type="button"
                  className="btn btn-light"
                  value="Re-Open"
                  onClick={this.onChangeTodoCompleted}
                />
              ) : (
                <input
                  type="button"
                  className="btn btn-success"
                  value="MARK AS COMPLETE"
                  onClick={this.onChangeTodoCompleted}
                />
              )}
            </div>
          </div>
        </div>
        <form onSubmit={this.onSubmit}>
          <div className="shadow-sm p-3 mb-2 bg-white rounded">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label htmlFor="">Task Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.todo_title}
                    placeholder={this.state.todo_title}
                    onChange={this.onChangeTodoTitle}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label htmlFor="">Responsible</label>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.todo_responsible}
                    placeholder={this.state.todo_responsible}
                    onChange={this.onChangeTodoResponsible}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <label htmlFor="">Short Description</label>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    onChange={this.onChangeTodoDescription}
                    value={this.state.todo_description}
                    placeholder="Task description"
                    id="exampleFormControlTextarea1"
                    rows="3"
                  ></textarea>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <label htmlFor="">Attached Image</label>
                <div style={{ paddingBottom: 7 }}>
                  {this.state.todo_img_url == "" ? (
                    <div></div>
                  ) : (
                    <div>
                      <div style={{ float: "left", paddingBottom: 7 }}>
                        <img
                          src={
                            process.env.REACT_APP_IMG_FOLDER +
                            this.state.todo_img_url
                          }
                          style={{ width: 80 }}
                          onChange={this.handleFileUpload}
                          onClick={this.handleFileUploadClick}
                        />
                      </div>
                      <div
                        style={{ float: "left" }}
                        className="icon-delete-image"
                      >
                        <img
                          onClick={this.onDeleteImage}
                          style={{ float: "right" }}
                          src="/images/delete.png"
                          className="icon-delete"
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className="custom-file">
                  <input
                    label="upload file"
                    type="file"
                    className="custom-file-input"
                    onChange={this.handleFileUpload}
                    onClick={this.handleFileUploadClick}
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="validatedCustomFile"
                  >
                    Update Image
                  </label>
                </div>
                {this.state.upload_status == 1 ? (
                  <div className="">Please wait...</div>
                ) : (
                  <div className=""></div>
                )}

                {this.state.upload_status == 2 ? (
                  <div className="">File uploaded</div>
                ) : (
                  <div className=""></div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-12">
                <label htmlFor="exampleInputPassword1">
                  Repeats (Number of times)
                </label>
                <div className="form-group">
                  <input
                    type="Number"
                    className="form-control"
                    value={this.state.task_value}
                    placeholder="Repeats"
                    onChange={this.onChangeTaskValue}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label htmlFor="">Task Due Date</label>
                  <div>
                    <img
                      src="/images/days_left.jpg"
                      className="task-due-icon"
                    />
                    <DatePicker
                      className="form-control"
                      dateFormat="yyyy-MM-dd hh:mm:ss"
                      value={this.state.task_duedate}
                      onChange={this.onChangeTaskDueDate}
                      selected={this.state.startDate}
                      showMonthDropdown
                      useShortMonthInDropdown
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-12"></div>
              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label htmlFor="">Link to a Goal</label>
                  <select
                    name="customSearch"
                    className="custom-search-select form-control"
                    onChange={this.handleChange}
                    id="sel1"
                    value={this.state.task_goalid}
                  >
                    <option>Select Item</option>
                    {goalOptions}
                  </select>
                </div>
                {/* <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.task_goalid}
                    placeholder="goalid"
                    onChange={this.onChangeTaskGoalId}
                  />
                </div> */}
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label style={{ paddingTop: 10 }}>Priority</label>
                  <br />
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="priorityOptions"
                      id="priorityLow"
                      value="Low"
                      checked={this.state.todo_priority === "Low"}
                      onChange={this.onChangeTodoPriority}
                    />
                    <label className="form-check-label">Low</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="priorityOptions"
                      id="priorityMedium"
                      value="Medium"
                      checked={this.state.todo_priority === "Medium"}
                      onChange={this.onChangeTodoPriority}
                    />
                    <label className="form-check-label">Medium</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="priorityOptions"
                      id="priorityHigh"
                      value="High"
                      checked={this.state.todo_priority === "High"}
                      onChange={this.onChangeTodoPriority}
                    />
                    <label className="form-check-label">High</label>
                  </div>

                  {/* <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="completedCheckBox"
                      name="completedCheckBox"
                      onChange={this.onChangeTodoCompleted}
                      checked={this.state.todo_completed}
                      value={this.state.todo_completed}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="completedCheckBox"
                    >
                      Completed
                    </label>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="form-group">
                <input
                  type="submit"
                  value="Update"
                  disabled={
                    this.state.todo_title.length < 2 ||
                    this.state.task_duedate == ""
                  }
                  className="btn btn-primary"
                />{" "}
                <div style={{ float: "right" }}>
                  <img
                    onClick={this.onDeleteConfirm}
                    style={{ float: "right" }}
                    src="/images/delete.png"
                    className="icon-delete"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Are you sure?
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                You're going to delete Task <b>{this.state.todo_description}</b>{" "}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.onDelete}
                >
                  I'm Sure
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="taskComplete"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="taskCompleteLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="taskCompleteLabel">
                  Awesome!
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" style={{ textAlign: "center" }}>
                <img src="/images/trophy-icon.jpg" style={{ width: 100 }} />
                <br />
                <span>
                  You completed a task. <br />
                  Keep up the good work!!!.
                  <br />
                  <br />
                  {/* <span style={{ fontSize: 10 }}>
                    Click update button to complete the action.
                  </span> */}
                </span>

                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                {/* <Link
                  onClick={this.setNewTask}
                  to=""
                  className="btn btn-primary "
                >
                  Set your first task!
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
