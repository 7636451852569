import React, { Component } from "react";
import queryString from "query-string";
import Loader from "react-loader-spinner";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  loginUser,
  loginFBUser,
  loginGoogleUser,
} from "../../actions/authActions";
import classnames from "classnames";
import Navbar from "../layout/Navbar";
import axios from "axios";
var utils = require("../../utils/utils");

class Login extends Component {
  //#region constructor
  constructor(props) {
    super(props);

    this.resend = this.resend.bind(this);
    this.responseFacebook = this.responseFacebook.bind(this);
    this.fbClicked = this.fbClicked.bind(this);

    this.state = {
      isFBLoggedIn: false,
      isGoogleLoggedIn: false,
      email: "",
      password: "",
      userimage: "",
      errors: {},
      reg: "",
      fbData: [],
      googleData: [],
      userID: "",
      name: "",
      loader: false,
    };

    console.log(this.props.params);
  }
  //#endregion

  //#region SendNotification
  SendNotification() {
    console.log("Send Notification" + utils.userDetails().subscription);
    ///check for service worker
    if ("serviceWorker" in navigator) {
      send().catch((err) => console.log(err));
    }

    //register SW, register push, send push
    async function send() {
      // register service worker
      console.log("send register");
      console.log("registering push serice worker...");
      const register = await navigator.serviceWorker.register(
        "/sw/sw_push.js",
        {
          scope: "/sw/",
        }
      );

      console.log("service worker registred ");

      // //regigster push
      // console.log("register Push ... ");
      // const subscription = await register.pushManager.subscribe({
      //   userVisibleOnly: true,
      //   applicationServerKey: urlBase64ToUint8Array(publicVapidKey)
      // });

      console.log("Push registred...");

      // Send Push Notification
      console.log("Sending Push");
      console.log(utils.userDetails().subscription);

      const apiUrl = process.env.REACT_APP_API_URL + "push/subscribe";
      await fetch(apiUrl, {
        method: "POST",
        body: utils.userDetails().subscription,
        headers: { "Content-Type": "application/json" },
      });

      console.log("Push Sent..." + apiUrl);
    }
  }
  //#endregion

  //#region  urlBase64ToUint8Array
  urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, "+")
      .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }
  //#endregion

  //#region resend
  resend() {
    console.log(this.state.email);

    const objUser = {};
    axios
      .post(
        process.env.REACT_APP_API_URL + "users/resend/" + this.state.email,
        objUser
      )
      .then((res) => console.log(res.data));
  }
  //#endregion

  //#region componentWillReceiveProps
  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.SendNotification();
      this.props.history.push("/dash"); // push user to dashboard when they login
    }
    if (nextProps.errors) {
      this.setState({ loader: false });
      this.setState({
        errors: nextProps.errors,
      });
    }
  }
  //#endregion

  //#region onChange
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  //#endregion

  //#region onSubmit
  onSubmit = (e) => {
    this.setState({ loader: true });

    e.preventDefault();
    const userData = {
      email: this.state.email.toLowerCase(),
      password: this.state.password,
    };
    console.log("userData:" + userData);
    this.props.loginUser(userData); // since we handle the redirect within our component, we don't need to pass in this.props.history as a parameter
  };
  //#endregion

  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    if (values.reg != undefined) {
      this.setState({
        reg: values.reg,
      });
    }
  }

  responseFacebook = (response) => {
    console.log(response);

    this.setState({
      fbData: response,
      isFBLoggedIn: true,
      userimage: response.picture.data.url,
      userID: response.userID,
      name: response.name,
      email: response.email,
    });

    let userData = {
      fbid: this.state.userID,
      name: this.state.name,
      email: this.state.email,
      picture: this.state.userimage,
    };
    console.log(userData);

    this.props.loginFBUser(userData);

    // const userData = {
    //   fbid: this.state.fbData.userID,
    //   name: this.state.fbData.name,
    //   picture: this.state.fbData.picture.data.url
    // };

    //console.log(userData);
    // this.props.loginFBUser(userData);
  };

  fbClicked = () => {
    //console.log(response);
    // if (response != "" && response != null) {
    //   this.setState({ fbData: response });
    let userData = {
      fbid: this.state.userID,
      name: this.state.name,
      email: this.state.email,
      picture: this.state.userimage,
    };

    //console.log(this.state.userimage);
    //console.log(this.state.userID);
    //console.log(userData);
    //this.props.loginFBUser(userData);
    // }
  };

  render() {
    const { errors } = this.state;
    console.log("errors.notactiveuser" + errors.notactiveuser);

    let fbContent;
    let googleContent;

    if (this.state.isFBLoggedIn) {
      fbContent = (
        <div>
          Hello {this.state.fbData.name} <img src={this.state.userimage} />
        </div>
      );
    } else {
      fbContent = (
        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_APP_ID} // "414575405884347"
          autoLoad={false}
          fields="name,email,picture"
          callback={this.responseFacebook}
          // onClick={this.fbClicked}
          cssClass="fb-btn"
          textButton="Continue with Facebook"
        />
      );
    }

    const responseGoogle = (response) => {
      console.log(response.profileObj);

      if (response.profileObj != undefined) {
        this.setState({
          googleData: response,
          isGoogleLoggedIn: true,
          userimage:
            response.profileObj.imageUrl != undefined
              ? response.profileObj.imageUrl
              : "",
          userID: response.profileObj.googleId,
          name: response.profileObj.name,
          email: response.profileObj.email,
        });
      }

      let userData = {
        googlgeid: this.state.userID,
        name: this.state.name,
        email: this.state.email,
        picture: this.state.userimage,
      };
      console.log(userData);

      this.props.loginGoogleUser(userData);
    };

    if (this.state.isGoogleLoggedIn) {
      googleContent = (
        <div>
          Hello {this.state.googleData.name} <img src={this.state.userimage} />
        </div>
      );
    } else {
      googleContent = (
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID} //"557756302838-l6e22663el787c8a0cgdrsh73f74irmu.apps.googleusercontent.com" //CLIENTID NOT CREATED YET
          buttonText="Continue with Google"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          icon={false}
          className="google-btn"
        >
          <span>Continue with Google </span>
        </GoogleLogin>
      );
    }

    return (
      <div className="container">
        <div style={{ marginTop: 20 }}>
          <Navbar />

          <div>
            {this.state.loader ? (
              <div
                style={{
                  width: "100%",
                  height: "100",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loader
                  type="ThreeDots"
                  color="#0f8baa"
                  height={100}
                  width={100}
                />
              </div>
            ) : (
              <div>
                {this.state.reg != "" ? (
                  <div className="alert alert-success alert-dismissible fade show">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                    >
                      &times;
                    </button>
                    <b>Great! You've successfully signed up for Bealyo.</b>
                    <br /> We've sent you a link to confirm your email address.
                    Please check your inbox. it could take up to 15 minutes to
                    show up in your inbox.
                  </div>
                ) : (
                  <span></span>
                )}

                <div className="row">
                  <div className="col-1 col-md-3"></div>
                  <div className="col-10 col-md-6" style={{ marginLeft: -13 }}>
                    <h4>Login</h4>
                  </div>
                  <div className="col-1 col-md-3"></div>
                </div>
                <div className="row ">
                  <div className="col-1 col-md-3"></div>
                  <div className="col-10 col-md-6  shadow-sm p-3 mb-2 bg-white rounded">
                    <div>
                      <p className="grey-text text-darken-1 login-text">
                        Don't have an account?{" "}
                        <Link to="/register">Sign Up</Link>
                      </p>
                      <form noValidate onSubmit={this.onSubmit}>
                        {errors.notactiveuser ||
                        errors.email ||
                        errors.emailnotfound ? (
                          <div className="alert alert-danger" role="alert">
                            {errors.email}
                            {errors.emailnotfound}
                            {errors.notactiveuser}
                            {errors.notactiveuser != "" &&
                            errors.notactiveuser != undefined ? (
                              <div>
                                <a href="#" onClick={this.resend()}>
                                  Resend
                                </a>
                              </div>
                            ) : (
                              <span></span>
                            )}

                            {errors.emailnotfound != "" &&
                            errors.emailnotfound != undefined ? (
                              <div>
                                <Link to="/register">Sign Up</Link>
                              </div>
                            ) : (
                              <span></span>
                            )}
                          </div>
                        ) : (
                          <span></span>
                        )}

                        <div className="form-group">
                          {/* <label for="email">Email</label> */}
                          <input
                            onChange={this.onChange}
                            value={this.state.email}
                            error={errors.email}
                            id="email"
                            type="email"
                            placeholder="Email"
                            className={classnames("form-control", {
                              invalid:
                                errors.email ||
                                errors.emailnotfound ||
                                errors.notactiveuser,
                            })}
                          />

                          <span className="red-text"></span>
                        </div>
                        <div className="form-group">
                          {/* <label for="password">Password</label> */}
                          <input
                            onChange={this.onChange}
                            value={this.state.password}
                            error={errors.password}
                            id="password"
                            type="password"
                            placeholder="Passowrd"
                            className={classnames("form-control", {
                              invalid:
                                errors.password || errors.passwordincorrect,
                            })}
                          />

                          <span className="red-text"></span>
                        </div>
                        <div className="form-group">
                          <button
                            style={{
                              width: "100%",
                              borderRadius: "3px",
                              letterSpacing: "1.5px",
                              marginTop: "1rem",
                            }}
                            type="submit"
                            className="btn btn-primary btn-lg"
                          >
                            Login
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="row">
                      <div className="col-1 "></div>
                      <div className="col-4 orsep"></div>
                      <div
                        className="col-2 "
                        style={{ textAlign: "center", paddingBottom: 15 }}
                      >
                        OR
                      </div>
                      <div className="col-4 orsep"></div>
                      <div className="col-1 "></div>
                    </div>
                    <div className="row">
                      <div
                        className="col-12"
                        style={{
                          textAlign: "center",
                          alignContent: "center",
                          paddingBottom: 25,
                        }}
                      >
                        {googleContent}
                      </div>
                    </div>

                    <div className="row">
                      <div
                        className="col-12"
                        style={{
                          textAlign: "center",
                          alignContent: "center",
                          paddingBottom: 25,
                        }}
                      >
                        {fbContent}
                      </div>
                    </div>
                  </div>
                  <div className="col-1 col-md-3"></div>
                </div>
                <div className="row">
                  <div
                    className="col-12"
                    style={{
                      textAlign: "center",
                      alignContent: "center",
                    }}
                  >
                    <Link to="/forgot" value="">
                      Forgot your password?
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  loginFBUser: PropTypes.func.isRequired,
  loginGoogleUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, {
  loginUser,
  loginFBUser,
  loginGoogleUser,
})(Login);
