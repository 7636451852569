import React, { Component } from "react";
import axios from "axios";
import PNavbar from "../private-route/Nav";
import { trackPromise } from "react-promise-tracker";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

const ListObject = (props) => (
  <tr>
    <td className={props.todo.todo_completed ? "completed" : ""}>
      {props.todo.todo_description}
    </td>
    <td className={props.todo.todo_completed ? "completed" : ""}>
      {props.todo.todo_priority}
    </td>
    <td>
      <Link to={"/edit/" + props.todo._id}>Edit</Link>
    </td>
  </tr>
);

class List extends Component {
  constructor(props) {
    super();
    this.state = { lists: [] };
  }

  componentDidMount() {
    console.log(process.env.REACT_APP_API_URL + "lists/list");
    //let jwtToken = localStorage.getItem("jwtToken").replace("Bearer ", "");
    //console.log("jwtToken:" + jwtToken);
    trackPromise(
      axios
        .get(process.env.REACT_APP_API_URL + "s/list")
        .then((response) => {
          this.setState({ goals: response.data });
        })
        .catch(function (error) {
          console.log(error);
        })
    );
  }

  listList() {
    return this.state.lists.map(function (currentTodo, i) {
      return <ListObject todo={currentTodo} key={i} />;
    });
  }
  render() {
    return (
      <div className="container">
        <PNavbar />
        <div>
          <Breadcrumb>
            <Breadcrumb.Item href="/dash">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Lists</Breadcrumb.Item>
          </Breadcrumb>
          <div>
            <h3 className="float-left">Lists</h3>

            <Button
              href="/createlist"
              className="float-right"
              variant="primary"
            >
              <b> +</b>
            </Button>
          </div>

          <table className="table table-striped" style={{ marginTop: 20 }}>
            <tbody>{this.listList()}</tbody>
            <span>You haven't created any lists yet</span>
          </table>
        </div>
      </div>
    );
  }
}

export default List;
