import React, { Component } from "react";
import PNavbar from "../private-route/Nav";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { logoutUser } from "../../actions/authActions";
import { connect } from "react-redux";
import axios from "axios";
import Moment from "react-moment";
import "moment-timezone";
import Breadcrumb from "react-bootstrap/Breadcrumb";
var utils = require("../../utils/utils");

class User extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user_email: "",
      user_join_date: "",
      img_url: "/images/icons/avatar.jpg",
      jwtToken: "",
      _id: "",
      items: [{ id: 1 }, { id: 2 }, { id: 3 }],
      tasks: [],
      goals: [],
      score: []
    };
  }

  // userList() {
  //   //console.log(this.state.items);
  //   return this.state.items.map(function(currentTodo, i) {
  //     return <UserObjectDiv todo={currentTodo} key={i} />;
  //   });
  //   //return <div>dd</div>;
  // }

  handleFileUpload = event => {
    console.log("handleFileUpload " + event.target.files);
    this.setState({ file: event.target.files });

    var fileName = event.target.files[0].name;

    if (event.target.files != undefined) {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);

      axios
        .post(process.env.REACT_APP_API_URL + "files/test-upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          // handle your response;
          console.log(process.env.REACT_APP_IMG_FOLDER + response.data.Key);
          this.setState({
            img_url: process.env.REACT_APP_IMG_FOLDER + response.data.Key
          });

          console.log(
            process.env.REACT_APP_API_URL + "users/update/" + this.state._id
          );

          const objUser = {
            img_url: this.state.img_url
          };

          axios
            .post(
              process.env.REACT_APP_API_URL + "users/update/" + this.state._id,
              objUser
            )
            .then(res => console.log(res.data));

          document.getElementsByClassName("avatar")[0].innerHTML =
            process.env.REACT_APP_IMG_FOLDER + response.data.Key;

          //console.log(this.state.upload_status);
        })
        .catch(error => {
          // handle your error
        });
    }
    //this.setState({ todo_img_url: event.target.files[0].Key });
  };

  componentDidMount() {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          "users/user" +
          this.props.match.params.id
      )
      .then(response => {
        //console.log(response.data);
        this.getTasks();
        this.getGoals();

        this.setState({
          user_email: response.data.email,
          user_join_date: response.data.date,
          name: response.data.name,
          score: response.data.Score,
          img_url:
            response.data.img_url == ""
              ? "/images/avatar.png"
              : response.data.img_url,
          _id: response.data._id
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  //#region getTasks
  getTasks() {
    var apiurl = "todos/all/user" + this.props.match.params.id;

    const obj = {
      filter_task_type: "",
      filter_task_goal: "",
      filter_task_priority: ""
    };

    //trackPromise(
    axios
      .post(process.env.REACT_APP_API_URL + apiurl, obj)
      .then(response => {
        this.setState({ tasks: response.data });
        console.log(response.data);
      })
      .catch(function(error) {
        console.log(error);
      });
    // );
  }
  //#endregion

  //#region getGoals
  getGoals() {
    // Get goals

    // trackPromise(
    axios
      .post(
        process.env.REACT_APP_API_URL +
          "goals/user" +
          this.props.match.params.id
      )
      .then(response => {
        this.setState({ goals: response.data });
        console.log("goals/user/");
        console.log(response.data);
      })
      .catch(function(error) {
        console.log(error);
      });
    // );
  }
  //#endregion

  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
    //this.closeNav();
  };

  render() {
    let totalScore = 0;

    for (var i = 0; i < this.state.score.length; i++) {
      totalScore = totalScore + parseInt(this.state.score[i].score);
    }
    return (
      <div style={{ backgroundColor: "#f3f3f3" }}>
        <PNavbar />
        {/* <div className="container">
          <Breadcrumb>
            <Breadcrumb.Item href="/dash">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>My Profile</Breadcrumb.Item>
          </Breadcrumb>
        </div> */}
        {/* <div>
          <h1 className="h1-home-header-main float-left">My Profile</h1>
        </div>
        <br /> */}
        <div className="container-fluid">
          <div
            className="row"
            style={{ backgroundColor: "#4267b2", height: 100, marginTop: -11 }}
          >
            <div></div>
          </div>
        </div>
        <div className="container" style={{ minHeight: 600 }}>
          <div className="row">
            <div
              className="col-12 col-md-2"
              style={{ paddingRight: 30, textAlign: "center" }}
            >
              <div className="image-upload">
                <label htmlFor="file-input">
                  <img className="avatar-circle" src={this.state.img_url} />
                  {/* <div className="">{this.state.name}</div> */}
                </label>

                <input
                  id="file-input"
                  onChange={this.handleFileUpload}
                  type="file"
                />
              </div>
            </div>
            <div className="col-12 col-md-10">
              <div className="me-name">{this.state.name}</div>
              {/* <div>{this.state.user_email}</div>
              <div>
                {" "}
                JOIN DATE:
                <Moment parse="YYYY-MM-DD" date={this.state.user_join_date} />
              </div>
              <div>
                <Link
                  to="/"
                  className="btn btn-secondary"
                  onClick={this.onLogoutClick}
                >
                  Logout
                </Link>
              </div> */}
            </div>

            {/* <b>Your details:</b> */}
            {/* <input
            label="upload file"
            type="file"
            className="file-upload-user"
            onChange={this.handleFileUpload}
          />
          <br />
          <img src={this.state.user_img_url} alt="Avatar" className="avatar" /> */}
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-4 hm-column">
              <div className="shadow-sm p-3 mb-2 bg-white rounded">
                <div className="me-box">
                  <div className="row" style={{ paddingLeft: 70 }}>
                    <div className="col-2 col-md-2">
                      <img
                        src="/images/icons/trophy.png"
                        className="hp-trophy"
                        alt=""
                      />{" "}
                    </div>
                    <div
                      className="col-10 col-md-10  me-link"
                      style={{
                        textAlign: "left",
                        paddingTop: 1,
                        fontSize: 18,
                        fontWeight: 500
                      }}
                    >
                      {totalScore} Points
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-md-4 hm-column">
              <div className="shadow-sm p-3 mb-2 bg-white rounded">
                <div className="me-box">
                  <div className="row" style={{ paddingLeft: 70 }}>
                    <div className="col-2 col-md-2">
                      <img
                        src="/images/goal.png"
                        className="hp-trophy"
                        alt=""
                      />
                    </div>
                    <div
                      className="col-10 col-md-10 me-link"
                      style={{ textAlign: "left", paddingTop: 1 }}
                    >
                      {this.state.goals.length > 0 ? (
                        <Link to={"/goals/" + this.props.match.params.id}>
                          {" "}
                          {this.state.goals.length} Goals
                        </Link>
                      ) : (
                        <span> {this.state.goals.length} Goals</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-md-4 hm-column">
              <div className="shadow-sm p-3 mb-2 bg-white rounded">
                <div className="me-box">
                  <div className="row" style={{ paddingLeft: 70 }}>
                    <div className="col-2 col-md-2">
                      <img
                        src="/images/task.png"
                        className="hp-trophy"
                        alt=""
                      />
                    </div>
                    <div
                      className="col-10 col-md-10  me-link"
                      style={{ textAlign: "left", paddingTop: 1 }}
                    >
                      {this.state.tasks.length > 0 ? (
                        <Link to={"/tasks/" + this.props.match.params.id}>
                          {" "}
                          {this.state.tasks.length} Tasks
                        </Link>
                      ) : (
                        <span> {this.state.tasks.length} Tasks</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

User.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { logoutUser })(User);
