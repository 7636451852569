import React, { Component } from "react";
import Navbar from "../layout/Navbar";
import axios from "axios";
import Breadcrumb from "react-bootstrap/Breadcrumb";

class Forgot extends Component {
  constructor() {
    super();

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      email: ""
    };
  }

  onChange(e) {
    console.log("onChange");
    this.setState({
      email: e.target.value
    });
  }

  //#region onSubmit
  onSubmit(e) {
    console.log("submit forgot");
    e.preventDefault();

    const resetEmail = {
      email: this.state.email
    };

    console.log(resetEmail);

    // axios
    //   .post(process.env.REACT_APP_API_URL + "users/forgot", resetEmail)
    //   .then(res => console.log(res.data));

    // if (this.state.task_goalid !== "" && this.state.task_goalid !== undefined) {
    //   this.props.history.push("/goal/" + this.state.task_goalid);
    // } else {
    //   this.props.history.push("/");
    // }
  }

  render() {
    return (
      <div className="container">
        <Navbar />
        <div>
          <Breadcrumb>
            <Breadcrumb.Item href="/dash">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Forgot password?</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div>
          <h1 className="h1-home-header-main">Forgot password</h1>
          <div className="shadow-sm p-3 mb-2 bg-white rounded">
            <p>
              Enter your email address and we will send you a link to reset it.
            </p>
            <form onSubmit={this.onSubmit}>
              <div className="form-group">
                {/* <label for="email">Email</label> */}
                <input
                  onChange={this.onChange}
                  value={this.state.email}
                  id="email"
                  type="email"
                  placeholder="Email"
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <button
                  style={{
                    width: "100%",
                    borderRadius: "3px",
                    letterSpacing: "1.5px"
                  }}
                  type="submit"
                  className="btn btn-primary btn-lg"
                >
                  Reset Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Forgot;
