import React, { Component } from "react";
import { Link } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import axios from "axios";
import PropTypes, { number } from "prop-types";
import logo from "../../logo.png";
import { logoutUser } from "../../actions/authActions";
import { connect } from "react-redux";
var utils = require("../../utils/utils");

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notifications: []
    };

    this.getNotifications();
  }

  //#region getNotifications
  getNotifications() {
    // Get notifications
    trackPromise(
      axios
        .post(process.env.REACT_APP_API_URL + "notifications/open")
        .then(response => {
          console.log(response.data);
          this.setState({ notifications: response.data });
        })
        .catch(function(error) {
          console.log(error);
        })
    );
  }
  //#endregion

  closeNav = () => {
    document.getElementById(
      "navbarCollapse"
    ).className = document
      .getElementById("navbarCollapse")
      .className.replace("show", "");
    console.log("close nav");
  };

  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
    this.closeNav();
  };

  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-dark fixed-top bg-dark">
          <div className="navsub">
            <a className="navbar-brand-img" href="/dash">
              <img
                className="navbar-brand-img-inner"
                src={logo}
                alt="bealyo.com"
              />
            </a>
            <Link to="/dash" className="navbar-brand">
              BEALYO
            </Link>

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="navbar-collapse collapse" id="navbarCollapse">
              <ul className="navbar-nav mr-auto">
                {/* <li className="navbar-item">
                  <Link
                    to="/create"
                    className=" btn btn-primary btn-new btn-add-task"
                    onClick={this.closeNav}
                  >
                    <b>+</b>
                  </Link>
                </li> */}
                {/* <li className="navbar-item">
                  <Link
                    to="/upload"
                    className="nav-link"
                    onClick={this.closeNav}
                  >
                    Upload File <span className="sr-only">(current)</span>
                  </Link>
                </li> */}
                <li className="navbar-item">
                  <div className="mobile-menu">
                    <img
                      src="/images/icons/menu-home.png"
                      style={{ width: 14 }}
                    />
                  </div>
                  <div>
                    <Link
                      to="/dash"
                      className="nav-link"
                      onClick={this.closeNav}
                    >
                      Home <span className="sr-only">(current)</span>
                    </Link>
                  </div>
                </li>
                <li className="navbar-item">
                  <div className="mobile-menu">
                    <img
                      src="/images/icons/menu-goal.png"
                      style={{ width: 14 }}
                    />
                  </div>
                  <div>
                    <Link
                      to="/goals"
                      className="nav-link"
                      onClick={this.closeNav}
                    >
                      Goals <span className="sr-only">(current)</span>
                    </Link>
                  </div>
                </li>
                <li className="navbar-item">
                  <div className="mobile-menu">
                    <img
                      src="/images/icons/menu-task.png"
                      style={{ width: 14 }}
                    />
                  </div>
                  <div>
                    <Link to="/" className="nav-link" onClick={this.closeNav}>
                      Tasks <span className="sr-only">(current)</span>
                    </Link>
                  </div>
                </li>
                <li className="navbar-item">
                  <div className="mobile-menu">
                    <img
                      src="/images/icons/menu-com.png"
                      style={{ width: 14 }}
                    />
                  </div>
                  <div>
                    <Link
                      to="/community"
                      className="nav-link"
                      onClick={this.closeNav}
                    >
                      Community <span className="sr-only">(current)</span>
                    </Link>
                  </div>
                </li>
                <li className="navbar-item">
                  <div className="mobile-menu">
                    <img
                      src="/images/icons/menu-home.png"
                      style={{ width: 14 }}
                    />
                  </div>
                  <div>
                    <Link
                      to="/users/me"
                      className="nav-link"
                      onClick={this.closeNav}
                    >
                      Me
                    </Link>
                  </div>
                </li>
                {/* <li className="navbar-item">
                  <Link
                    to="/lists"
                    className="nav-link"
                    onClick={this.closeNav}
                  >
                    Lists <span className="sr-only">(current)</span>
                  </Link>
                </li> */}

                <li className="navbar-item notification">
                  <Link
                    to="/notifications"
                    className="nav-link"
                    onClick={this.closeNav}
                  >
                    <img src="/images/noti.png" className="icon-noti" />

                    {Number(this.state.notifications.length) > 0 &&
                    this.props.notifaction != "clear" ? (
                      <span className="badge">
                        {Number(this.state.notifications.length) <= 9
                          ? this.state.notifications.length
                          : "9+"}
                      </span>
                    ) : (
                      <span></span>
                    )}
                  </Link>
                </li>

                <li className="navbar-item">
                  <Link
                    to="/settings"
                    className="nav-link"
                    onClick={this.closeNav}
                  >
                    <img src="/images/settings.png" style={{ width: 18 }} />
                  </Link>
                </li>
                {/* <li className="navbar-item">
                  <Link
                    to="/"
                    className="nav-link"
                    onClick={this.onLogoutClick}
                  >
                    Logout
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { logoutUser })(Navbar);
