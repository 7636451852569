import React, { Component } from "react";
import axios from "axios";
import PNavbar from "../private-route/Nav";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";

export default class GoalCats extends Component {
  constructor(props) {
    super(props);

    this.onChangeGoalTitle = this.onChangeGoalTitle.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      goal_title: ""
    };
  }

  onChangeGoalTitle(e) {
    console.log("onChangeGoalTitle");
    this.setState({
      goal_title: e.target.value
    });
  }

  //   onChangeTodoResponsiblen(e) {
  //     console.log("onChangeTodoResponsiblen");
  //     this.setState({
  //       todo_responsible: e.target.value
  //     });
  //   }
  //   onChangeTodoPriority(e) {
  //     console.log("onChangeTodoPriority");
  //     this.setState({
  //       todo_priority: e.target.value
  //     });
  //   }
  onSubmit(e) {
    e.preventDefault();

    console.log("Form submitted:");
    console.log("Goal Title:" + this.state.goal_title);

    const newGoal = {
      goal_title: this.state.goal_title,
      u_id: ""
    };

    let jwtToken = localStorage.getItem("jwtToken").replace("Bearer ", "");
    console.log("jwtToken:" + jwtToken);

    const headers = {
      "x-access-token": `${jwtToken}`
    };

    axios
      .post(process.env.REACT_APP_API_URL + "goals/add", newGoal)
      .then(res => console.log(res.data));

    this.setState({
      list_title: ""
    });

    this.props.history.push("/goals");
  }

  render() {
    return (
      <div className="container">
        <PNavbar />
        <div>
          <Breadcrumb>
            <Breadcrumb.Item href="/dash">Home</Breadcrumb.Item>

            <Breadcrumb.Item active>Goal Categories</Breadcrumb.Item>
          </Breadcrumb>

          <h5>Goal Categories</h5>
          <div className="shadow-sm p-3 mb-2 bg-white rounded">
            <span>Please choose one of the categories or choose Custom.</span>

            <div
              className="row"
              style={{
                marginLeft: -3,
                textTransform: "uppercase",
                paddingRight: 12
              }}
            >
              <div className="shadow-sm col-sm-2 bg-blue rounded  goal-cell">
                <Link to={"/creategoal/3"}>
                  <b>Sport</b>
                </Link>
              </div>

              <div className="shadow-sm col-sm-2  bg-blue  rounded goal-cell">
                <span>
                  <Link to={"/creategoal/1"}>
                    <b>Diet</b>
                  </Link>
                </span>
              </div>

              <div className="shadow-sm col-sm-2 bg-blue rounded  goal-cell">
                <span className="">
                  <Link to={"/creategoal/2"}>
                    <b>Educate</b>
                  </Link>
                </span>
              </div>

              <div className="shadow-sm col-sm-2 bg-blue rounded  goal-cell">
                <Link to={"/creategoal/4"}>
                  <b>Work</b>
                </Link>
              </div>

              <div className="shadow-sm col-sm-2 bg-blue rounded  goal-cell">
                <Link to={"/creategoal/5"}>
                  <b>Habits</b>
                </Link>
              </div>

              <div className="shadow-sm col-sm-2 bg-blue rounded  goal-cell">
                <Link to={"/creategoal/6"}>
                  <b>Health</b>
                </Link>
              </div>

              <div className="shadow-sm col-sm-2 bg-blue rounded  goal-cell">
                <Link to={"/creategoal/7"}>
                  <b>Money</b>
                </Link>
              </div>

              <div className="shadow-sm col-sm-2 bg-blue-custom rounded  goal-cell">
                <Link to={"/creategoal/8"}>
                  <b>Custom</b>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
