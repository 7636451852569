import React, { Component } from "react";
import PNavbar from "../private-route/Nav";
import { trackPromise } from "react-promise-tracker";
import axios from "axios";
import Moment from "react-moment";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from "react-html-parser";

const NotificationObjectDiv = props => (
  <div className="col-lg-6 col-md-12">
    <div
      className={
        props.noti.status === "0"
          ? "shadow-sm p-3 mb-2 bg-white-read rounded "
          : "shadow-sm p-3 mb-2 bg-white rounded "
      }
      key={props.noti.key}
    >
      <div className="row">
        <div className="col-4 col-md-3">
          <img
            className="avatar-circle-small"
            src={
              props.noti.user_data[0].img_url === ""
                ? "/images/icons/avatar.jpg"
                : props.noti.user_data[0].img_url
            }
          />
        </div>
        <div className="col-8 col-md-9">
          <div> {ReactHtmlParser(props.noti.title)}</div>
          <div>
            <Moment format="YYYY/MM/DD hh:mm">{props.noti.date}</Moment>
          </div>
        </div>
      </div>
    </div>
  </div>
);

class Notifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notifications: []
    };

    this.updateNotifications();
    this.getNotifications();
  }

  //#region  componentDidMount
  componentDidMount() {
    this.updateNotifications();
    this.getNotifications();
  }
  //#endregion

  //#region getNotifications
  getNotifications() {
    console.log(process.env.REACT_APP_API_URL + "notifications/");

    // Get notifications
    trackPromise(
      axios
        .post(process.env.REACT_APP_API_URL + "notifications/")
        .then(response => {
          console.log(response.data);
          this.setState({ notifications: response.data });
        })
        .catch(function(error) {
          console.log(error);
        })
    );
  }
  //#endregion

  //#region getNotifications
  updateNotifications() {
    console.log(process.env.REACT_APP_API_URL + "notifications/update/all");

    // Get notifications
    // trackPromise(
    axios
      .post(process.env.REACT_APP_API_URL + "notifications/update/all")
      .then(response => {
        console.log(response.data);
        //this.setState({ notifications: response.data });
      })
      .catch(function(error) {
        console.log(error);
      });
    // );
  }
  //#endregion

  notificationList() {
    //console.log(this.state.items);
    return this.state.notifications.map(function(currentNotification, i) {
      return <NotificationObjectDiv noti={currentNotification} key={i} />;
    });
  }

  render() {
    return (
      <div className="container">
        <PNavbar notifaction="clear" />
        <div>
          <Breadcrumb>
            <Breadcrumb.Item href="/dash">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Notifications</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div>
          <h1 className="h1-home-header-main">Notifications</h1>
        </div>
        <div className="row">
          {this.notificationList().length > 0 ? (
            this.notificationList()
          ) : (
            <div style={{ paddingLeft: 15 }}>
              {" "}
              <div className="shadow-sm p-3 mb-2 bg-white rounded ">
                <span>You don't have any notifications right now </span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Notifications;
