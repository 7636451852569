import React, { Component } from "react";
import axios from "axios";
import PNavbar from "../private-route/Nav";
import { trackPromise } from "react-promise-tracker";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { Container, Link as LinkButton } from "react-floating-action-button";

import { fadeIn } from "react-animations";
import Radium, { StyleRoot } from "radium";
const $ = window.$;

const styles = {
  fadeIn: {
    animation: "x 0.5s",
    animationName: Radium.keyframes(fadeIn, "fadeIn")
  }
};

const GoalObjectDiv = props => (
  <div className="col-lg-6 col-md-12" style={{ paddingBottom: 5 }}>
    <div className="shadow-sm p-3 mb-2 bg-green rounded goal-header-panel">
      <div className="float-left goal-title-div">
        <StyleRoot>
          <span className="goal-title" style={styles.fadeIn}>
            {/* <Link to={"/goal/" + props.todo._id}>{props.todo.goal_title} </Link> */}
            {props.todo.goal_title}
          </span>
        </StyleRoot>
        <div>{props.todo.goal_desc}</div>

        <div className="goal-add-task-link">
          <div>
            {props.todo.tasks_docs.length === 0 ? (
              <a
                className=" btn btn-success dash-new-link-w"
                href={"/create/" + props.todo._id}
              >
                Add Goal First Task!
              </a>
            ) : (
              <span></span>
            )}

            <span></span>
          </div>
        </div>
      </div>
      <div className="float-right" style={{ marginTop: -5 }}>
        <div>Tasks</div>
        <div style={{ textAlign: "center", fontSize: 24, fontWeight: 600 }}>
          {props.todo.tasks_docs.length}
        </div>
      </div>
      <div className="float-right" style={{ marginRight: 12, marginTop: -5 }}>
        <div>Days </div>
        <div style={{ textAlign: "center", fontSize: 24, fontWeight: 600 }}>
          {props.todo.goal_period}
        </div>
      </div>
      <div className="float-right" style={{ marginTop: -5 }}>
        <div style={{ marginLeft: -20 }}>
          {/* <Link to={"/editgoal/" + props.todo._id}>
            <img src="/images/dup.png" className="task-dup-icon " />
          </Link> */}
          {/* <Link to={"/editgoal/" + props.todo._id}>
            <img src="/images/more-icon.jpg" className="task-edit-icon-blue " />
          </Link> */}
        </div>
      </div>
    </div>
  </div>
);

const GoalObject = props => (
  <tr>
    <td
      className={
        props.todo.todo_completed
          ? "col-sm-8 item-title completed"
          : "col-sm-8 item-title"
      }
    >
      {props.todo.goal_title}
    </td>
    <td className={props.todo.todo_completed ? "completed" : ""}>
      {props.todo.goal_catid}
    </td>
    <td className="float-right">
      <Link to={"/editgoal/" + props.todo._id}>
        <img src="/images/edit.png" style={{ width: 18 }} />
      </Link>
    </td>
  </tr>
);

class Goal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      goals: [],
      tasks_from_db: true,
      initialItems: [],
      items: []
    };

    this.setNewTask = this.setNewTask.bind(this);
    this.getGoals();
  }

  getGoals() {
    trackPromise(
      axios
        .get(
          process.env.REACT_APP_API_URL + "goals/" + this.props.match.params.id
        )
        .then(response => {
          console.log(response.data);
          this.setState({ goals: response.data });
          this.setState({
            initialItems: response.data,
            items: response.data
          });

          if (
            response.data.length === 1 &&
            response.data[0].tasks_docs.length === 0
          )
            $("#exampleModal").modal("show");

          if (response.data.length) this.setState({ tasks_from_db: true });
          else this.setState({ tasks_from_db: false });
        })
        .catch(function(error) {
          console.log(error);
        })
    );
  }

  filterList = event => {
    let items = this.state.initialItems;
    //console.log(items[0].todo_description);
    items = items.filter(item => {
      return (
        item.goal_title
          .toString()
          .toLowerCase()
          .search(event.target.value.toLowerCase()) !== -1
      );
    });
    this.setState({ items: items });
    console.log(items);
  };

  componentDidMount() {
    console.log(process.env.REACT_APP_API_URL + "goals/");
    //let jwtToken = localStorage.getItem("jwtToken").replace("Bearer ", "");
    //console.log("jwtToken:" + jwtToken);
    this.getGoals();
  }

  setNewTask(e) {
    //console.log(this.state.items);
    $("#exampleModal").modal("toggle");
    window.location = "/create/" + this.state.items[0]._id;
    //this.props.history.push("/goalcats");
  }

  componentDidUpdate(prevProps, prevState) {
    console.log(prevProps);
    console.log(prevState);

    //Typical usage, don't forget to compare the props
    //if (this.props.match.params.id !== prevState._id) {
    //  this.getGoals();
    // }
  }
  // componentDidUpdate() {
  //   //this.getGoals();
  //   // axios
  //   //   .get(process.env.REACT_APP_API_URL + "goals/")
  //   //   .then(response => {
  //   //     this.setState({ goals: response.data });
  //   //   })
  //   //   .catch(function(error) {
  //   //     console.log(error);
  //   //   });
  // }

  goalList() {
    return this.state.items.map(function(currentTodo, i) {
      return <GoalObjectDiv todo={currentTodo} key={i} />;
    });
  }

  render() {
    setTimeout(() => {
      $("#exampleModal").modal("hide");
    }, 15000);

    //this.getGoals();
    return (
      <div className="container">
        <PNavbar />
        <div>
          <Breadcrumb>
            <Breadcrumb.Item href="/dash">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/goals">Goals</Breadcrumb.Item>
            <Breadcrumb.Item active>Goal</Breadcrumb.Item>
          </Breadcrumb>
          <div>
            {this.state.items.length ? (
              <h1 className="h1-home-header-main float-left">
                Goals ({this.state.items.length})
              </h1>
            ) : (
              <h1 className="h1-home-header-main float-left">Goals</h1>
            )}
          </div>
          <div className="float-right">
            {this.state.tasks_from_db ? (
              <input
                type="text"
                placeholder="Search goal"
                onChange={this.filterList}
                className="task-search-box"
              />
            ) : (
              <span></span>
            )}
          </div>
          <br />
          <div style={{ marginTop: 20 }}>
            {this.goalList().length ? (
              <div className="row">{this.goalList()}</div>
            ) : (
              <div>
                {!this.state.tasks_from_db ? (
                  <div className="shadow-sm p-3 mb-2 bg-white rounded ">
                    <span>You haven't created any goals yet</span>
                    <br />
                    <br />
                    <Link to="/goalcats" className="btn btn-primary ">
                      Set your first goal!
                    </Link>
                  </div>
                ) : (
                  <div className="shadow-sm p-3 mb-2 bg-white rounded ">
                    <span>You haven't created any goals yet</span>
                    <br />
                    <br />
                    <Link to="/goalcats" className="btn btn-primary ">
                      Set your first goal!
                    </Link>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <Container>
          <LinkButton
            href="/goalcats"
            tooltip="Create Goal"
            icon="far fa-sticky-note"
          >
            {" "}
            <b className="plus-button"> G</b>
          </LinkButton>
          <LinkButton
            href="/create"
            tooltip="Create Task"
            icon="fas fa-user-plus"
            className="fab-item btn btn-link btn-lg text-white"
          >
            {" "}
            <b className="plus-button"> T</b>
          </LinkButton>
          {/* <Button
            tooltip="The big plus button!"
            href="/create"
            icon="fas fa-plus"
            rotate={true}
            onClick={() => alert("FAB Rocks!")}
          /> */}
          <LinkButton
            href="#"
            icon="far fa-sticky-note"
            className="plus-button-bg"
          >
            <b className="plus-button-plus"> +</b>
          </LinkButton>
        </Container>

        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Awesome! you created your first goal
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <span>
                  In order to achieve your goal it must be divided into tasks.
                </span>
                <br />
                <span>
                  Completing all goals tasks will help you achieve your goal
                  quickly.
                </span>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <Link
                  onClick={this.setNewTask}
                  to=""
                  className="btn btn-primary "
                >
                  Set your first task!
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Goal;
