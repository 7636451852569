import React, { Component } from "react";
import axios from "axios";
import PNavbar from "../private-route/Nav";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";
import SuggestedGoals from "../goals/suggested-goals.component";

export default class CreateGoal extends Component {
  //#region constructor
  constructor(props) {
    super(props);

    this.onChangeGoalTitle = this.onChangeGoalTitle.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSetGoal = this.onSetGoal.bind(this);

    this.state = {
      goal_title: "",
      goal_catid: ""
    };
  }
  //#endregion

  //#region onSetGoal
  onSetGoal(e, t) {
    console.log(t);
    //let val = e.target.value;
    this.setState({
      goal_title: t
    });
  }
  //#endregion

  //#region onChangeGoalTitle
  onChangeGoalTitle(e) {
    console.log("onChangeGoalTitle");
    this.setState({
      goal_title: e.target.value
    });
  }
  //#endregion

  //#region  onSubmit
  onSubmit(e) {
    e.preventDefault();

    console.log("Form submitted:");
    console.log("Goal Title:" + this.state.goal_title);

    const newGoal = {
      goal_title: this.state.goal_title,
      goal_catid: this.props.match.params.id
    };

    // let jwtToken = localStorage.getItem("jwtToken").replace("Bearer ", "");
    // console.log("jwtToken:" + jwtToken);

    // const headers = {
    //   "x-access-token": `${jwtToken}`
    // };

    axios
      .post(process.env.REACT_APP_API_URL + "goals/add", newGoal)
      .then(res => console.log(res.data));

    this.setState({
      list_title: ""
    });

    this.props.history.push("/goals");
  }
  //#endregion

  render() {
    return (
      <div className="container">
        <PNavbar />
        <div>
          <Breadcrumb>
            <Breadcrumb.Item href="/dash">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/goalcats">Goal Categories</Breadcrumb.Item>
            <Breadcrumb.Item active>Choose Goal</Breadcrumb.Item>
          </Breadcrumb>

          <SuggestedGoals id={this.props.match.params.id} />
        </div>
      </div>
    );
  }
}
