import React, { Component } from "react";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";
import PNavbar from "../private-route/Nav";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from "react-moment";
import { fadeIn } from "react-animations";
var utils = require("../../utils/utils");
const $ = window.$;

const CommentObjectDiv = (props) => (
  <div className="col-lg-12 col-md-12">
    <div key={props.comment.key}>
      <div className="row">
        <div className="col-4 col-md-2">
          <img
            className="avatar-circle-small"
            src={
              props.comment.user_data[0].img_url === ""
                ? "/images/icons/avatar.jpg"
                : props.comment.user_data[0].img_url
            }
          />
        </div>
        <div className="col-8 col-md-10">
          <div> {props.comment.task_comment}</div>
          <div>
            <Moment format="YYYY/MM/DD hh:mm">{props.comment.date}</Moment>
          </div>
        </div>
      </div>
    </div>
    {props.index < props.total - 1 ? (
      <div className="row">
        <div className="dash-top-box">
          <div className="dropdown-divider"></div>
        </div>
      </div>
    ) : (
      <span></span>
    )}
  </div>
);

export default class Task extends Component {
  //#region constructor
  constructor(props) {
    super(props);

    this.onChangeTodoDescription = this.onChangeTodoDescription.bind(this);
    this.onChangeTodoResponsible = this.onChangeTodoResponsible.bind(this);
    this.onChangeTodoPriority = this.onChangeTodoPriority.bind(this);
    this.onChangeTodoCompleted = this.onChangeTodoCompleted.bind(this);
    this.onChangeTaskComment = this.onChangeTaskComment.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onDeleteImage = this.onDeleteImage.bind(this);
    this.setComment = this.setComment.bind(this);

    this.state = {
      todo_title: "",
      todo_description: "",
      todo_responsible: "",
      todo_priority: "",
      todo_completed: false,
      todo_img_url: "",
      upload_status: "",
      task_value: "",
      task_duedate: new Date(),
      startDate: new Date(),
      task_goalid: this.props.match.params.id,
      arrayOfData: [],
      task_comment: "",
      task_comments: [],
    };

    this.getComments();
  }
  //#endregion

  //#region  commentList
  commentList() {
    //console.log(this.state.items);
    let totalNum = this.state.task_comments.length;
    return this.state.task_comments.map(function (currentComment, i, totalNum) {
      return (
        <CommentObjectDiv
          comment={currentComment}
          key={i}
          index={i}
          total={totalNum.length}
        />
      );
    });
  }
  //#endregion

  //#region componentDidMount
  componentDidMount() {
    axios
      .get(
        process.env.REACT_APP_API_URL + "todos/" + this.props.match.params.id
      )
      .then((response) => {
        var dateFormat = require("dateformat");
        // var nDate = dateFormat(
        //   response.data.task_duedate,
        //   "yyyy-mm-dd hh:MM:ss"
        // );
        var nDate = new Date(response.data.task_duedate.replace(/-/g, "/"));

        this.setState({
          todo_description: response.data.todo_description,
          todo_title: response.data.todo_title,
          todo_responsible: response.data.todo_responsible,
          todo_priority: response.data.todo_priority,
          todo_img_url: response.data.todo_img_url,
          todo_completed: response.data.todo_completed,
          task_value: response.data.task_value,
          task_duedate: nDate,
          task_goalid: response.data.task_goalid,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  //#endregion

  //#region onChangeTodoDescription
  onChangeTodoDescription(e) {
    this.setState({
      todo_description: e.target.value,
    });
  }
  //#endregion

  //#region onChangeTodoResponsible
  onChangeTodoResponsible(e) {
    this.setState({
      todo_responsible: e.target.value,
    });
  }
  //#endregion

  //#region onChangeTodoPriority
  onChangeTodoPriority(e) {
    this.setState({
      todo_priority: e.target.value,
    });
  }
  //#endregion

  //#region onChangeTaskComment
  onChangeTaskComment(e) {
    this.setState({
      task_comment: e.target.value,
    });
  }
  //#endregion

  //#region onChangeTodoCompleted
  onChangeTodoCompleted(e) {
    this.setState({
      todo_completed: !this.state.todo_completed,
    });
  }
  //#endregion

  //#region handleFileUpload
  handleFileUpload = (event) => {
    this.setState({
      upload_status: 1,
    });
    console.log("Task - handleFileUpload " + event.target.files);
    this.setState({ file: event.target.files });

    var fileName = event.target.files[0].name;
    console.log(document.getElementsByClassName("custom-file-label"));
    document.getElementsByClassName("custom-file-label")[0].innerHTML =
      fileName;

    if (event.target.files != undefined) {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);

      axios
        .post(process.env.REACT_APP_API_URL + "files/test-upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // handle your response;
          console.log(
            // "https://bealyo-upload-files.s3.us-east-2.amazonaws.com/" +
            response.data.Key
          );
          this.setState({
            todo_img_url:
              // "https://bealyo-upload-files.s3.us-east-2.amazonaws.com/" +
              response.data.Key,
          });
          this.setState({
            upload_status: 2,
          });

          console.log(this.state.upload_status);
        })
        .catch((error) => {
          // handle your error
        });
    }
    //this.setState({ todo_img_url: event.target.files[0].Key });
  };
  //#endregion

  //#region getComments
  getComments() {
    // console.log(
    //   process.env.REACT_APP_API_URL +
    //     "todos/taskcomments/" +
    //     this.props.match.params.id
    // );

    // Get notifications
    trackPromise(
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "todos/taskcomments/" +
            this.props.match.params.id
        )
        .then((response) => {
          console.log(response.data);
          this.setState({ task_comments: response.data });
        })
        .catch(function (error) {
          console.log(error);
        })
    );
  }
  //#endregion

  //#region onSubmit
  onSubmit(e) {
    e.preventDefault();

    var dateFormat = require("dateformat");
    var nDate = dateFormat(this.state.task_duedate, "yyyy-mm-dd hh:MM:ss");

    const obj = {
      todo_description: this.state.todo_description,
      todo_responsible: this.state.todo_responsible,
      todo_priority: this.state.todo_priority,
      todo_img_url: this.state.todo_img_url,
      todo_completed: this.state.todo_completed,
      task_value: this.state.task_value,
      task_duedate: nDate,
    };

    axios
      .post(
        process.env.REACT_APP_API_URL +
          "todos/update/" +
          this.props.match.params.id,
        obj
      )
      .then((res) => console.log(res.data));

    this.props.history.push("/");
  }
  //#endregion

  //#region onDelete
  onDelete() {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          "todos/remove/" +
          this.props.match.params.id
      )
      .then(console.log("Deleted"))
      .catch((err) => console.log(err));

    this.props.history.push("/");
  }
  //#endregion

  //#region onChangeTaskValue
  onChangeTaskValue(e) {
    console.log("onChangeTaskValue");
    this.setState({
      task_value: e.target.value,
    });
  }
  //#endregion

  //#region onChangeTaskDueDate
  onChangeTaskDueDate = (date) => {
    console.log("onChangeTaskDueDate");

    this.setState({
      startDate: date,
    });

    this.setState({
      task_duedate: date,
    });

    console.log(date);
  };
  //#endregion

  //#region comment
  comment() {
    //console.log($("#commentModal"));

    $("#CommentModal").modal("toggle");
  }
  //#endregion

  //#region setComment
  setComment(e) {
    $("#CommentModal").modal("toggle");
    this.setState({
      task_comments: [],
    });

    const objComment = {
      task_comment: this.state.task_comment,
      tid: this.props.match.params.id,
      date: new Date(),
    };

    axios
      .post(
        process.env.REACT_APP_API_URL +
          "todos/addcomment/" +
          this.props.match.params.id,
        objComment
      )
      .then(
        (res) => console.log(res.data),
        this.setState({
          task_comment: "",
        }),
        this.setState({ task_comments: [] })
      );
    this.task_comments = [];

    trackPromise(
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "todos/taskcomments/" +
            this.props.match.params.id
        )
        .then((response) => {
          console.log(response.data);
          this.setState({ task_comments: response.data });
        })
        .catch(function (error) {
          console.log(error);
        })
    );

    //this.getComments();

    // console.log("this.props.gid" + this.props.gid);
    // if (this.props.gid !== undefined) {
    //   this.setState({ filter_gid: this.props.gid });
    // }
    // this.getTasks();
  }

  //#endregion

  //#region  onDeleteImage
  onDeleteImage() {
    const imgObj = {
      todo_img_url: this.state.todo_img_url,
    };

    console.log(this.state.todo_img_url);
    axios
      .post(
        process.env.REACT_APP_API_URL +
          "files/delete/" +
          this.props.match.params.id,
        imgObj
      )
      .then(
        console.log("Image Deleted"),
        this.setState({
          todo_img_url: "",
        })
      )
      .catch((err) => console.log(err));

    // this.props.history.push("/");
  }
  //#endregion

  render() {
    return (
      <div style={{ backgroundColor: "#f3f3f3" }}>
        <div
          className="modal fade"
          id="CommentModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add Comment
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {" "}
                <textarea
                  className="form-control"
                  onChange={this.onChangeTaskComment}
                  value={this.state.task_comment}
                  placeholder="Please write your comment ..."
                  id="exampleFormControlTextarea1"
                  rows="3"
                ></textarea>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <Link
                  onClick={this.setComment}
                  to="#"
                  className="btn btn-primary "
                >
                  Save Comment
                </Link>
              </div>
            </div>
          </div>
        </div>

        <PNavbar />

        {/* <Breadcrumb>
          <Breadcrumb.Item href="/dash">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/">Tasks</Breadcrumb.Item>
          <Breadcrumb.Item active>{this.state.todo_title}</Breadcrumb.Item>
        </Breadcrumb> */}
        <div className="container-fluid">
          <div
            className="row"
            style={{ backgroundColor: "#4267b2", height: 100, marginTop: -11 }}
          >
            <div></div>
          </div>
        </div>
        <div className="container" style={{ minHeight: 600 }}>
          <div className="row">
            {this.state.todo_img_url != "" ? (
              <div
                className="col-12 col-lg-3 task-img"
                style={{ textAlign: "center" }}
              >
                <img
                  className="task-square"
                  src={
                    process.env.REACT_APP_IMG_FOLDER + this.state.todo_img_url
                  }
                  style={{ width: 250, height: 250 }}
                />
              </div>
            ) : (
              <div
                className="col-12 col-lg-3 task-img"
                style={{ textAlign: "center" }}
              >
                <img
                  className="task-square"
                  src="/images/noimage.png"
                  style={{ width: 250, height: 250 }}
                />
              </div>
            )}
            <div className="col-12 col-lg-9 ">
              <h3>{this.state.todo_title}</h3>
              <div>{this.state.todo_description}</div>
              <div>{this.state.todo_priority}</div>
              <div>
                <Moment format="YYYY/MM/DD hh:mm">
                  {this.state.task_duedate}
                </Moment>
              </div>
              {this.state.todo_completed ? (
                <div>
                  status:{" "}
                  <span style={{ color: "green", fontWeight: 600 }}>
                    Completed
                  </span>{" "}
                </div>
              ) : (
                <div>status: Incomplete</div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12" style={{ marginTop: 15, marginLeft: -10 }}>
              <b>{this.state.task_comments.length} Comments </b>
            </div>

            <div className=" col-12 shadow-sm p-3 mb-2 bg-white rounded">
              <div className="row">{this.commentList()}</div>
            </div>
            <div className="row">
              <div className="col-12">
                <input
                  type="button"
                  className="btn btn-primary"
                  onClick={this.comment}
                  value="+ Comment"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
