import React, { Component } from "react";
import axios from "axios";
import PNavbar from "../../components/private-route/Nav";
import { trackPromise } from "react-promise-tracker";
import Image from "react-bootstrap/Image";

class FileUpload extends Component {
  constructor() {
    super();
    this.state = {
      file: null
    };
  }

  submitFile = event => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("file", this.state.file[0]);

    trackPromise(
      axios
        .post(process.env.REACT_APP_API_URL + "files/test-upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          // handle your response;
          console.log(
            "https://bealyo-upload-files.s3.us-east-2.amazonaws.com/" +
              response.data.Key
          );
          document.getElementById("imgUpload").src =
            "https://bealyo-upload-files.s3.us-east-2.amazonaws.com/" +
            response.data.Key;
          // this.imgSrc =
          //   "https://bealyo-upload-files.s3.us-east-2.amazonaws.com/" +
          //   response.data.Key;
          //this.renderList(this.imgSrc);
        })
        .catch(error => {
          // handle your error
        })
    );
  };

  handleFileUpload = event => {
    this.setState({ file: event.target.files });
  };

  // added from the mentioned post
  imageExists(image_url) {
    var http = new XMLHttpRequest();
    http.open("HEAD", image_url, false);
    http.send();
    return http.status != 404;
  }

  //componentDidUpdate() {
  //  console.log("this.imgSrc" + this.imgSrc);
  //var url = this.imgSrc;
  //##const alt = "http://punemirror.indiatimes.com/photo/55813567.cms";
  //## var src = this.imageExists(url) ? url : alt;
  //this.setState({ src });
  //}

  render() {
    return (
      <div className="container">
        <PNavbar />
        <div style={{ marginTop: 20 }}>
          <h3>Upload File</h3>
        </div>
        <form onSubmit={this.submitFile}>
          <input
            label="upload file"
            type="file"
            onChange={this.handleFileUpload}
          />
          <button type="submit">Send</button>
        </form>
        <br></br>
        <Image id="imgUpload" style={{ width: 250 }} roundedCircle />
      </div>
    );
  }
}

export default FileUpload;
