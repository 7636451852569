"use strict";
import React, { Component } from "react";
import jwt_decode from "jwt-decode";

const bcrypt = require("bcryptjs");
//const jwt = require("jsonwebtoken");

export function userDetails() {
  let jwtTokenvar = "";
  jwtTokenvar = localStorage.getItem("jwtToken").replace("Bearer ", "");
  //console.log(jwtTokenvar);
  let decodedvar = "";
  /*jwt.verify(jwtTokenvar, "secret", function(err, decoded) {
    decodedvar = decoded;
  });*/
  decodedvar = jwt_decode(jwtTokenvar, { header: true });

  //console.log("decodedvar:" + JSON.stringify(decodedvar));
  return decodedvar;
}
