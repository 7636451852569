import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
//import AnimatedProgressProvider from "./AnimatedProgressProvider";
import ChangingProgressProvider from "../../utils/ChangingProgressProvider";

import "react-circular-progressbar/dist/styles.css";
import PNavbar from "../private-route/Nav";
import { trackPromise } from "react-promise-tracker";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import { Container, Link as LinkButton } from "react-floating-action-button";
import Countdown from "react-countdown-now";
import Moment from "react-moment";
import ReactSearchBox from "react-search-box";
import { Container as ContainerDND, Draggable } from "react-smooth-dnd";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { fadeIn } from "react-animations";
import Radium, { StyleRoot } from "radium";
const $ = window.$;
const Completionist = () => <span className="task-overdue">Task Overdue</span>;

const styles = {
  fadeIn: {
    animation: "x 0.5s",
    animationName: Radium.keyframes(fadeIn, "fadeIn")
  }
};

export default class TodoList extends Component {
  constructor(props) {
    super(props);
    this.countdownOnComplete = this.countdownOnComplete.bind(this);
    this.onChangeTodoCompleted = this.onChangeTodoCompleted.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.handleFilterTaskGoal = this.handleFilterTaskGoal.bind(this);
    this.handleFilterTaskType = this.handleFilterTaskType.bind(this);
    this.handleFilterTaskPriority = this.handleFilterTaskPriority.bind(this);
    this.calValueReport = this.calValueReport.bind(this);

    this.onDrop = this.onDrop.bind(this);
    this.state = {
      todos: [],
      tasks_from_db: true,
      countdown_completed: false,
      initialItems: [],
      items: [],
      goaldata: "",
      gid: "",
      arrayOfData: [],
      filter_task_type: "",
      filter_task_priority: "",
      filter_task_goal: "",
      filter_gid: "",
      tValue: 0,
      tReported: 0
    };

    this.getTasks();
    this.getGoals();

    //this.setState({ test: "2" });
    // this.props.action(this.state.v1, this.state.v2);
  }

  // componentWillReceiveProps(props) {
  //   const { refresh } = this.props;

  //   console.log("props.refresh");
  //   console.log(props.refresh);

  //   if (props.refresh !== refresh) {
  //     this.getTasks();
  //   }
  // }

  //#region getGoals
  getGoals() {
    // Get goals
    trackPromise(
      axios
        .get(process.env.REACT_APP_API_URL + "goals/")
        .then(response => {
          this.setState({ arrayOfData: response.data });
        })
        .catch(function(error) {
          console.log(error);
        })
    );
  }
  //#endregion

  //#region filterList
  filterList = event => {
    let items = this.state.initialItems;

    items = items.filter(item => {
      return (
        item.todo_title
          .toString()
          .toLowerCase()
          .search(event.target.value.toLowerCase()) !== -1
      );
    });
    this.setState({ items: items });
    //console.log(items);
  };
  //#endregion

  //#region countdownOnComplete
  countdownOnComplete() {
    this.setState({ countdown_completed: true });
  }
  //#endregion

  //#region getTasks
  getTasks() {
    var apiurl =
      this.props.gid != undefined
        ? "todos/" + "g/" + this.props.gid
        : "todos/all";

    if (this.state.filter_gid != "") {
      apiurl = "todos/" + "g/" + this.state.filter_gid;
    }

    if (this.props.gid != undefined) {
      this.setState({ filter_gid: this.props.gid });
    }
    //console.log("this.state.filter_gid:" + this.state.filter_gid);
    //console.log("apiurl:" + apiurl);

    const obj = {
      filter_task_type: this.state.filter_task_type,
      filter_task_goal: this.state.filter_task_goal,
      filter_task_priority: this.state.filter_task_priority
    };

    //console.log(obj);

    trackPromise(
      axios
        .post(process.env.REACT_APP_API_URL + apiurl, obj)
        .then(response => {
          this.setState({ todos: response.data });
          this.setState({
            initialItems: response.data,
            items: response.data
          });

          if (response.data.length) this.setState({ tasks_from_db: true });
          else this.setState({ tasks_from_db: false });

          var tValue = 0;
          var tReported = 0;

          for (var i = 0; i < this.state.items.length; i++) {
            if (
              this.state.items[i].task_value != "" &&
              this.state.items[i].task_value !== undefined &&
              this.state.items[i].task_value !== "NaN" &&
              this.state.items[i].task_value_reported != "" &&
              this.state.items[i].task_value_reported !== undefined &&
              this.state.items[i].task_value_reported !== "NaN"
            ) {
              tValue = tValue + Number(this.state.items[i].task_value);
              tReported =
                tReported + Number(this.state.items[i].task_value_reported);
            }
          }

          if (this.props.action != undefined)
            this.props.action(tValue, tReported);
          //this.state.setState({ tValue: tValue });
          //this.state.setState({ tReported: tReported });

          // console.log("this.state.tValue2");
          // console.log(this.state.tValue);
          // console.log("this.state.tReported2");
          // console.log(this.state.tReported);

          this.calValueReport();

          //console.log(response.data);
        })
        .catch(function(error) {
          console.log(error);
        })
    );
  }
  //#endregion

  //#region  calValueReport
  calValueReport() {
    var tValue = 0;
    var tReported = 0;

    for (var i = 0; i < this.state.items.length; i++) {
      if (
        this.state.items[i].task_value != "" &&
        this.state.items[i].task_value !== undefined &&
        this.state.items[i].task_value !== "NaN" &&
        this.state.items[i].task_value_reported != "" &&
        this.state.items[i].task_value_reported !== undefined &&
        this.state.items[i].task_value_reported !== "NaN"
      ) {
        tValue = tValue + Number(this.state.items[i].task_value);
        tReported = tReported + Number(this.state.items[i].task_value_reported);
      }
    }

    if (this.props.action != undefined) this.props.action(tValue, tReported);
    // this.state.setState({ tValue: tValue });
    // this.state.setState({ tReported: tReported });

    // console.log("this.state.tValue2");
    // console.log(this.state.tValue);
    // console.log("this.state.tReported2");
    // console.log(this.state.tReported);
  }
  //#endregion

  //#region  componentDidMount
  componentDidMount() {
    this.getTasks();
  }
  //#endregion

  //#region componentDidUpdate
  componentDidUpdate(prevProps, prevState) {
    // console.log("prevProps");
    // console.log(prevProps);
    // console.log("prevState");
    // console.log(prevState);
    // console.log("this.props");
    // console.log(this.props);

    //console.log(this.props.goaldata.rand);
    //console.log(prevProps.goaldata.rand);
    if (
      this.props.goaldata != undefined &&
      this.props.goaldata.rand !== prevProps.goaldata.rand
    ) {
      this.getTasks();
    }
  }
  //#endregion

  //#region onSubmit
  onSubmit(e) {
    e.preventDefault();
    console.log("Search");
  }
  //#endregion

  //#region getGoalDetails
  getGoalDetails(gid) {
    axios
      .get(process.env.REACT_APP_API_URL + "goals/" + gid)
      .then(response => {
        var goalObj = {
          title: response.data.goal_title,
          id: response.data._id
        };
        return goalObj;
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  //#endregion

  //#region handleClick
  handleClick = () => {
    console.log("clicked!");
  };
  //#endregion

  //#region onChangeTodoCompleted
  onChangeTodoCompleted(e, id) {
    var items = this.state.items;
    let currentTodo = this.state.items[e];
    console.log(currentTodo.todo_completed);
    if (
      currentTodo.todo_completed == "false" ||
      currentTodo.task_value !== currentTodo.task_value_reported
    ) {
      $("#taskComplete").modal("show");
    }
    currentTodo.todo_completed = "true";
    currentTodo.task_value_reported++;
    items[e] = currentTodo;

    this.setState({
      items: items
    });

    const obj = {
      todo_completed: true
    };

    axios
      .post(process.env.REACT_APP_API_URL + "todos/completed/" + id, obj)
      .then(
        res => console.log(res.data),
        //this.forceUpdate(),

        // console.log("this.state.tValue"),
        // console.log(this.state.tValue),
        // console.log("this.state.tReported"),
        // console.log(this.state.tReported),

        //this.props.action(this.state.tValue, this.state.tReported)
        this.calValueReport()
      )
      .catch(error => {
        console.log("ERRORRRR" + error);
      });

    setTimeout(() => {
      $("#taskComplete").modal("hide");
    }, 10000);

    // this.setState({
    //   items: []
    // });

    // this.getTasks();

    // console.log("this.state.tValue");
    // console.log(this.state.tValue);
    // console.log("this.state.tReported");
    // console.log(this.state.tReported);

    // this.props.action(this.state.tValue, this.state.tReported);
  }
  //#endregion

  //#region todoList
  todoList() {
    return this.state.items.map((currentTodo, i) => {
      var percentage = 0;
      // if (currentTodo.todo_completed === "true") {
        if(Number(currentTodo.task_value) > 0  && Number(currentTodo.task_value_reported) < Number(currentTodo.task_value)){
          percentage = 100 * (Number(currentTodo.task_value_reported) / Number(currentTodo.task_value));
         }
         else if (currentTodo.task_value_reported >= currentTodo.task_value) {
        percentage = 100
        }else{
          percentage = 0 
        }
        //console.log("currentTodo.task_value > 0:" + (currentTodo.task_value > 0));
        //console.log("currentTodo.task_value_reported < currentTodo.task_value:" + (Number(currentTodo.task_value_reported) < Number(currentTodo.task_value)));
        

      percentage = Number(percentage.toFixed(1));
      //}

      // console.log("percentage");
      // console.log(percentage);

      return (
        <div className="col-lg-6 col-md-12" key={i}>
          <div
            className="shadow-sm p-3 mb-2 bg-white rounded"
            style={{ height: 160 }}
          >
            <div className="row task-row">
              {currentTodo.todo_img_url != "" ? (
                <div className="col-2 col-lg-2 task-img">
                  <img
                    src={
                      process.env.REACT_APP_IMG_FOLDER +
                      currentTodo.todo_img_url
                    }
                    className="task-image"
                  />
                </div>
              ) : (
                <div className="col-2 col-lg-2 task-img">
                  <img
                    src="/images/icons/no-task-image.png"
                    className="task-image"
                  />
                </div>
              )}

              <div className="col-7 col-lg-7 task-title-div">
                <StyleRoot>
                  <span className="task-title" style={styles.fadeIn}>
                    <Link to={"/task/" + currentTodo._id}>
                      {currentTodo.todo_title}{" "}
                    </Link>
                  </span>
                </StyleRoot>

                {currentTodo.goals_data.map(function(goal, i) {
                  return (
                    <div key={i}>
                      Goal:{" "}
                      <Link to={"/goal/" + currentTodo.task_goalid}>
                        {goal.goal_title}
                      </Link>
                    </div>
                  );
                })}
                <div>
                  Repeats: {currentTodo.task_value_reported}/
                  {currentTodo.task_value}
                </div>
                <div>
                  <img
                    src="/images/icons/comment.png"
                    style={{ width: 16 }}
                    alt="Comment"
                    title="Comment"
                  />{" "}
                  3 comments
                </div>
              </div>

              <div className="col-2 col-lg-2 ">
                <div className="task-speed">
                  <CircularProgressbar
                    value={percentage}
                    text={`${percentage}%`}
                    circleRatio={0.75}
                    styles={buildStyles({
                      rotation: 1 / 2 + 1 / 8,
                      strokeLinecap: "butt",
                      trailColor: "#eee"
                    })}
                  />
                </div>
              </div>
            </div>
            <div className="dropdown-divider"></div>
            <div className="row">
              <div className="col-1 col-lg-1">
                {currentTodo.todo_priority === "Low" ? (
                  <img
                    src="/images/icons/top-l.png"
                    style={{ width: 16 }}
                    alt="Low"
                    title="Low"
                  />
                ) : (
                  <span></span>
                )}
                {currentTodo.todo_priority === "Medium" ? (
                  <img
                    src="/images/icons/top-m.png"
                    style={{ width: 16 }}
                    alt="Medium"
                    title="Medium"
                  />
                ) : (
                  <span></span>
                )}
                {currentTodo.todo_priority === "High" ? (
                  <img
                    src="/images/icons/top-p.png"
                    style={{ width: 16 }}
                    alt="High"
                    title="High"
                  />
                ) : (
                  <span></span>
                )}
              </div>
              <div className="col-1 col-lg-6">
                <div className="task-countdown">
                  {currentTodo.todo_completed !== "true" ? (
                    <Moment format="YYYY/MM/DD">
                      {currentTodo.task_duedate}
                    </Moment>
                  ) : (
                    <span></span>
                  )}
                </div>
              </div>

              <div
                className="col-8 col-lg-4"
                style={{ textAlign: "right", marginRight: -15, marginLeft: 25 }}
              >
                <span id={currentTodo._id + "com"} style={{ display: "none" }}>
                  COMPLETED
                </span>

                {currentTodo.todo_completed === "true" &&
                currentTodo.task_value <= currentTodo.task_value_reported ? (
                  <span id={i}>COMPLETED </span>
                ) : (
                  <span>
                    <Link
                      id={currentTodo._id}
                      to="#"
                      onClick={() =>
                        this.onChangeTodoCompleted(i, currentTodo._id)
                      }
                    >
                      <span> MARK AS COMPLETE</span>
                    </Link>
                  </span>
                )}
              </div>
              <div
                className="col-1 col-lg-1"
                style={{ textAlign: "right", marginLeft: -10, marginTop: -2 }}
              >
                <Link to={"/edit/" + currentTodo._id}>
                  <img
                    src="/images/icons/more.png"
                    alt="More Details"
                    title="More Details"
                    style={{ width: 15 }}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }
  //#endregion

  //#region onDrop
  onDrop(dropResult) {
    const { removedIndex, addedIndex, payload, element } = dropResult;
    this.setState({
      items: this.reorder(this.state.items, removedIndex, addedIndex)
    });

    console.log(dropResult);
  }
  //#endregion

  //#region reorderlist
  reorderlist(removedIndex, addedIndex, items) {
    //console.log(items);
    console.log(
      "itemnum:" + removedIndex + " itemtonum:" + addedIndex + " items:" + items
    );

    var tmp = [];
    var itemTmp;
    for (var i = 0; i < items.length; i++) {
      itemTmp = items[removedIndex];
      if (i == removedIndex) {
        if (removedIndex < addedIndex)
          tmp.push(items[i + (addedIndex + removedIndex)]);
        else tmp.push(items[i - (removedIndex - addedIndex)]);
      } else if (i == addedIndex) {
        tmp.push(itemTmp);
      } else {
        tmp.push(items[i]);
      }
    }

    console.log(tmp);
    return tmp;
  }
  //#endregion

  //#region filter
  filter() {
    $("#filterModal").modal("toggle");
  }
  //#endregion

  //#region setFilter
  setFilter(e) {
    $("#filterModal").modal("toggle");
    this.setState({
      items: []
    });
    console.log("this.props.gid" + this.props.gid);
    if (this.props.gid !== undefined) {
      this.setState({ filter_gid: this.props.gid });
    }
    this.getTasks();
  }

  //#endregion

  //#region reorder
  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  //#endregion

  handleFilterTaskPriority(e) {
    this.setState({
      filter_task_priority: e.target.value
    });
  }

  handleFilterTaskType(e) {
    this.setState({
      filter_task_type: e.target.value
    });
  }

  handleFilterTaskGoal(e) {
    this.setState({
      filter_task_goal: e.target.value
    });
  }

  render() {
    let arrayOfData = this.state.arrayOfData;

    let goalOptions = arrayOfData.map(data => (
      <option key={data._id} value={data._id}>
        {data.goal_title}
      </option>
    ));

    return (
      <div className="container">
        <PNavbar />
        {this.props.goaldata == undefined ? (
          <div>
            <Breadcrumb>
              <Breadcrumb.Item href="/dash">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Tasks</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        ) : (
          <div></div>
        )}

        <div
          className="modal fade"
          id="filterModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Filter Results
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row row-filter">
                  <div className="col-3">Status:</div>
                  <div className="col-9">
                    <select
                      name="customSearch"
                      className="custom-search-select form-control"
                      onChange={this.handleFilterTaskType}
                      id="sel1"
                      value={this.state.filter_task_type}
                    >
                      <option value="">All</option>
                      <option value="1">Open</option>
                      <option value="2">Completed</option>
                    </select>
                  </div>
                </div>
                <div className="row row-filter">
                  <div className="col-3"> Priority:</div>
                  <div className="col-9">
                    <select
                      name="customSearch"
                      className="custom-search-select form-control"
                      onChange={this.handleFilterTaskPriority}
                    
                      id="sel1"
                      value={this.state.filter_task_priority}
                    >
                      <option value="">All</option>
                      <option value="High">High</option>
                      <option value="Medium">Medium</option>
                      <option value="Low">Low</option>
                    </select>
                  </div>
                </div>
                <div className="row row-filter">
                  <div className="col-3">Goal:</div>
                  <div className="col-9">
                    <select
                      name="customSearch"
                      className="custom-search-select form-control"
                      onChange={this.handleFilterTaskGoal}
                      id="sel1"
                      value={this.state.filter_task_goal}
                    >
                      <option value="">All</option>
                      {goalOptions}
                    </select>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <Link
                  onClick={this.setFilter}
                  to="#"
                  className="btn btn-primary "
                >
                  Apply Filter
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div>
          {this.state.items.length ? (
            <h1
              className={
                this.props.goaldata != undefined &&
                this.props.goaldata.title != undefined
                  ? "h1-home-header-fix h1-home-header-font float-left"
                  : "h1-home-header-main float-left"
              }
            >
              {this.props.goaldata != undefined &&
              this.props.goaldata.title != undefined ? (
                <div style={{ color: "#ffffff" }}>
                  {this.props.goaldata.title}{" "}
                  <span style={{ fontSize: 16 }}>
                    ({this.state.items.length})
                  </span>
                </div>
              ) : (
                <span>
                  Tasks{" "}
                  <span style={{ fontSize: 16 }}>
                    ({this.state.items.length})
                  </span>
                </span>
              )}{" "}
              {}
            </h1>
          ) : (
            <h1
              className={
                this.props.goaldata != undefined &&
                this.props.goaldata.title != undefined
                  ? "h1-home-header-fix h1-home-header-font float-left"
                  : "h1-home-header-main float-left"
              }
            >
              {" "}
              {this.props.goaldata != undefined &&
              this.props.goaldata.title != undefined ? (
                <span style={{ color: "#ffffff" }}>
                  {this.props.goaldata.title}
                </span>
              ) : (
                <span>Tasks</span>
              )}
            </h1>
          )}
        </div>
        <div
          className={
            this.props.goaldata != undefined &&
            this.props.goaldata.title != undefined
              ? "float-right task-search-input-fix"
              : "float-right task-search-input"
          }
        >
          {/* {this.state.tasks_from_db ? ( */}
          <div>
            <div className="float-right" style={{ paddingLeft: 5 }}>
              {" "}
              {this.props.goaldata != undefined &&
              this.props.goaldata.title != undefined ? (
                <img
                  src="/images/filter-w.png"
                  className="filter-icon "
                  onClick={this.filter}
                  alt="Filter Tasks"
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <img
                  src="/images/filter.png"
                  className="filter-icon "
                  onClick={this.filter}
                  alt="Filter Tasks"
                  style={{ cursor: "pointer" }}
                />
              )}
            </div>
            {this.state.tasks_from_db ? (
              <div className="float-right">
                <input
                  type="text"
                  placeholder="Search task"
                  onChange={this.filterList}
                  className="task-search-box"
                />
              </div>
            ) : (
              <div></div>
            )}
          </div>
          {/* ) : (
            <span></span>
          )} */}
        </div>
        <br />
        <div style={{ marginTop: 15 }}>
          {this.state.items.length ? (
            <div
              className={
                this.props.goaldata != undefined ? "row rows-goals" : "row"
              }
            >
              {this.todoList()}
            </div>
          ) : (
            <div
              style={
                this.props.goaldata == undefined
                  ? { marginLeft: 0 }
                  : { marginLeft: -11 }
              }
            >
              {!this.state.tasks_from_db ? (
                <div className="shadow-sm p-3 mb-2 bg-white rounded ">
                  <span>You haven't created any tasks yet</span>
                  <br />
                  <br />
                  <Link
                    to={
                      this.props.goaldata != undefined
                        ? "/create/" + this.props.goaldata.id
                        : "/create"
                    }
                    className="btn btn-primary "
                  >
                    Set your first task!
                  </Link>
                </div>
              ) : (
                <span></span>
              )}
            </div>
          )}
        </div>

        <Container>
          <LinkButton
            href="/goalcats"
            tooltip="Create Goal"
            icon="far fa-sticky-note"
          >
            {" "}
            <b className="plus-button"> G</b>
          </LinkButton>
          <LinkButton
            href={
              this.props.goaldata != undefined
                ? "/create/" + this.props.goaldata.id
                : "/create"
            }
            tooltip="Create Task"
            icon="fas fa-user-plus"
            className="fab-item btn btn-link btn-lg text-white"
          >
            {" "}
            <b className="plus-button"> T</b>
          </LinkButton>
          {/* <Button
            tooltip="The big plus button!"
            href="/create"
            icon="fas fa-plus"
            rotate={true}
            onClick={() => alert("FAB Rocks!")}
          /> */}
          <LinkButton
            href="#"
            icon="far fa-sticky-note"
            className="plus-button-bg"
          >
            <b className="plus-button-plus"> +</b>
          </LinkButton>
        </Container>

        <div
          className="modal fade"
          id="taskComplete"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="taskCompleteLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="taskCompleteLabel">
                  Awesome!
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" style={{ textAlign: "center" }}>
                <img src="/images/trophy-icon.jpg" style={{ width: 100 }} />
                <br />
                <div className="model-complete-task">
                  You completed a task <br />
                  Keep up the good work!!!
                  <br />
                </div>

                <br />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                {/* <Link
                  onClick={this.setNewTask}
                  to=""
                  className="btn btn-primary "
                >
                  Set your first task!
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
