import React, { Component } from "react";
import axios from "axios";
import PNavbar from "../private-route/Nav";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { trackPromise } from "react-promise-tracker";

export default class CreateTodo extends Component {
  constructor(props) {
    super(props);

    this.onChangeTodoDescription = this.onChangeTodoDescription.bind(this);
    this.onChangeTodoTitle = this.onChangeTodoTitle.bind(this);
    this.onChangeTodoResponsiblen = this.onChangeTodoResponsiblen.bind(this);
    this.onChangeTaskValue = this.onChangeTaskValue.bind(this);
    this.onChangeTaskGoalId = this.onChangeTaskGoalId.bind(this);
    this.onChangeTodoPriority = this.onChangeTodoPriority.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    // Set initial next day task
    var date = new Date();
    date.setDate(date.getDate() + 1);

    this.state = {
      todo_title: "",
      todo_description: "",
      todo_responsible: "",
      todo_priority: "Low",
      todo_completed: false,
      todo_img_url: "",
      upload_status: "",
      task_value: "0",
      task_value_change: "0",
      task_duedate: date,
      startDate: new Date("11/10/2019 11:05:00"),
      task_goalid: this.props.match.params.id,
      arrayOfData: [],
    };
    this.getGoals();
  }

  //#region getGoals
  getGoals() {
    // Get goals
    trackPromise(
      axios
        .get(process.env.REACT_APP_API_URL + "goals/")
        .then((response) => {
          this.setState({ arrayOfData: response.data });

          console.log(this.props.match.params.id);
          if (
            this.props.match.params.id !== "" &&
            this.props.match.params.id !== undefined
          )
            for (let i = 0; i < response.data.length; i++) {
              if (response.data[i]._id === this.props.match.params.id) {
                this.setState({
                  todo_title: response.data[i].goal_title + " Task",
                });
              }
            }
        })
        .catch(function (error) {
          console.log(error);
        })
    );
  }
  //#endregion

  //#region onChangeTodoDescription
  onChangeTodoDescription(e) {
    console.log("onChangeTodoDescription" + e.target.value);
    this.setState({
      todo_description: e.target.value,
    });
  }
  //#endregion

  //#region onChangeTodoTitle
  onChangeTodoTitle(e) {
    console.log("onChangeTodoTitle");
    this.setState({
      todo_title: e.target.value,
    });
  }
  //#endregion

  //#region onChangeTodoResponsiblen
  onChangeTodoResponsiblen(e) {
    console.log("onChangeTodoResponsiblen");
    this.setState({
      todo_responsible: e.target.value,
    });
  }
  //#endregion

  //#region onChangeTaskValue
  onChangeTaskValue(e) {
    // console.log(e);
    let value = e.target.value;

    if (!Number(value) && value !== "") {
      return;
    }
    this.setState({ task_value_change: 1 });

    this.setState({ task_value: e.target.value });

    // console.log("onChangeTaskValue");
    // this.setState({
    //   task_value: e.target.value
    // });
  }
  //#endregion

  //#region onChangeTaskGoalId
  onChangeTaskGoalId(e) {
    console.log("onChangeTaskGoalId");
    this.setState({
      task_goalid: e.target.value,
    });
  }
  //#endregion

  //#region onChangeTodoPriority
  onChangeTodoPriority(e) {
    console.log("onChangeTodoPriority");
    this.setState({
      todo_priority: e.target.value,
    });
  }
  //#endregion

  //#region onChangeTaskDueDate
  onChangeTaskDueDate = (date) => {
    console.log("onChangeTaskDueDate");

    this.setState({
      startDate: date,
    });

    this.setState({
      task_duedate: date,
    });

    console.log(date);
  };
  //#endregion

  //#region handleFileUploadClick
  handleFileUploadClick = (event) => {
    // this.setState({
    //   upload_status: 1
    // });
    // console.log(this.state.upload_status);
  };
  //#endregion

  //#region handleFileUpload
  handleFileUpload = (event) => {
    this.setState({
      upload_status: 1,
    });
    console.log("handleFileUpload " + event.target.files);
    this.setState({ file: event.target.files });

    var fileName = event.target.files[0].name;
    console.log(document.getElementsByClassName("custom-file-label"));
    document.getElementsByClassName("custom-file-label")[0].innerHTML =
      fileName;

    if (event.target.files !== undefined) {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);

      axios
        .post(process.env.REACT_APP_API_URL + "files/test-upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // handle your response;
          console.log(
            // "https://bealyo-upload-files.s3.us-east-2.amazonaws.com/" +
            response.data.Key
          );
          this.setState({
            todo_img_url:
              // "https://bealyo-upload-files.s3.us-east-2.amazonaws.com/" +
              response.data.Key,
          });
          this.setState({
            upload_status: 2,
          });

          console.log(this.state.upload_status);
        })
        .catch((error) => {
          // handle your error
        });
    }
    //this.setState({ todo_img_url: event.target.files[0].Key });
  };
  //#endregion

  //#region onSubmit
  onSubmit(e) {
    e.preventDefault();

    // console.log("Form submitted:");
    // console.log("Todo Description:" + this.state.todo_description);
    // console.log("Todo Title:" + this.state.todo_title);
    // console.log("Todo Responsible: " + this.state.todo_responsible);
    // console.log("Todo Priority: " + this.state.todo_priority);
    // console.log("Todo Completed: " + this.state.todo_completed);
    // console.log("Todo img url: " + this.state.todo_img_url);
    // console.log("Task value: " + this.state.task_value);
    // console.log("Task duedate: " + this.state.task_duedate);
    // console.log("file: " + this.state.file);

    var dateFormat = require("dateformat");
    var nDate = dateFormat(this.state.task_duedate, "yyyy-mm-dd hh:MM:ss");

    var imgUrl = "";

    const newTodo = {
      todo_description: this.state.todo_description,
      todo_title: this.state.todo_title,
      todo_responsible: this.state.todo_responsible,
      todo_priority: this.state.todo_priority,
      todo_completed: this.state.todo_completed,
      todo_img_url: this.state.todo_img_url,
      task_value: this.state.task_value === "" ? "1" : this.state.task_value,
      task_duedate: nDate,
      task_value_reported: 0,
      task_goalid: this.state.task_goalid,
      u_id: "",
    };

    axios
      .post(process.env.REACT_APP_API_URL + "todos/add", newTodo)
      .then((res) => console.log(res.data));

    this.setState({
      todo_description: "",
      todo_title: "",
      todo_responsible: "",
      todo_priority: "",
      todo_completed: false,
      todo_image_url: "",
      task_value: 0,
      task_duedate: "",
      task_goalid: "",
    });
    if (this.state.task_goalid !== "" && this.state.task_goalid !== undefined) {
      this.props.history.push("/goal/" + this.state.task_goalid);
    } else {
      this.props.history.push("/");
    }
  }
  //#endregion

  //#region handleChange
  //On the change event for the select box pass the selected value back to the parent
  handleChange = (event) => {
    let selectedValue = event.target.value;
    console.log(selectedValue);
    //this.onSelectChange(selectedValue);
    this.setState({
      task_goalid: selectedValue,
    });
  };
  //#endregion

  componentDidMount() {
    this.todo_title.focus();
  }

  render() {
    let arrayOfData = this.state.arrayOfData;
    let taskDefLimit = 0;
    if (this.state.task_value_change === "0") {
      for (var i = 0; i < arrayOfData.length; i++) {
        if (this.state.task_goalid == arrayOfData[i]._id) {
          taskDefLimit = arrayOfData[i].goal_period;
          // this.setState({ task_value: taskDefLimit });
        }
      }
    }

    console.log(arrayOfData);
    let goalOptions = arrayOfData.map((data) => (
      <option key={data._id} value={data._id}>
        {data.goal_title}
      </option>
    ));

    return (
      <div className="container">
        <PNavbar />

        <div>
          <Breadcrumb>
            <Breadcrumb.Item href="/dash">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/">Tasks</Breadcrumb.Item>
            <Breadcrumb.Item active>Add Task</Breadcrumb.Item>
          </Breadcrumb>

          <h3>Add Task </h3>
          <form onSubmit={this.onSubmit}>
            <div className="shadow-sm p-3 mb-2 bg-white rounded">
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <label>Task Name</label>
                    <input
                      ref={(input) => {
                        this.todo_title = input;
                      }}
                      type="text"
                      className="form-control"
                      value={this.state.todo_title}
                      placeholder="Type task name"
                      onChange={this.onChangeTodoTitle}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12  ">
                  <div className="form-group">
                    <label>Task Due Date</label>
                    <div>
                      <img
                        src="/images/days_left.jpg"
                        className="task-due-icon"
                      />
                      <DatePicker
                        className="form-control"
                        dateFormat="yyyy-MM-dd hh:mm:ss"
                        value={this.state.task_duedate}
                        placeholderText="Task due date"
                        selected={this.state.task_duedate}
                        onChange={this.onChangeTaskDueDate}
                        minDate={new Date()}
                        readonly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <label>Short Description</label>
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      onChange={this.onChangeTodoDescription}
                      value={this.state.todo_description}
                      placeholder="Task description"
                      id="exampleFormControlTextarea1"
                      rows="3"
                    ></textarea>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  {/* </div> */}

                  {/* <div className="col-lg-6 col-md-12"> */}
                  <div className="form-group">
                    <label style={{ paddingTop: 10 }}>Priority</label>
                    <br />
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="priorityOptions"
                        id="priorityLow"
                        value="Low"
                        checked={this.state.todo_priority === "Low"}
                        onChange={this.onChangeTodoPriority}
                      />
                      <label className="form-check-label">Low</label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="priorityOptions"
                        id="priorityMedium"
                        value="Medium"
                        checked={this.state.todo_priority === "Medium"}
                        onChange={this.onChangeTodoPriority}
                      />
                      <label className="form-check-label">Medium</label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="priorityOptions"
                        id="priorityHigh"
                        value="High"
                        checked={this.state.todo_priority === "High"}
                        onChange={this.onChangeTodoPriority}
                      />
                      <label className="form-check-label">High</label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <label>Repeats (Number of times)</label>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      value={
                        Number(taskDefLimit) + Number(this.state.task_value)
                      }
                      placeholder="Repeats"
                      onChange={this.onChangeTaskValue}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <label>Responsible</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.todo_responsible}
                      placeholder="Responsible"
                      onChange={this.onChangeTodoResponsiblen}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <label>Attached Image</label>
                  <div className="custom-file ">
                    <input
                      label="upload file"
                      type="file"
                      className="custom-file-input "
                      onChange={this.handleFileUpload}
                      onClick={this.handleFileUploadClick}
                    />
                    <label
                      className="custom-file-label form-control"
                      htmlFor="validatedCustomFile"
                    >
                      Add image
                    </label>
                  </div>

                  {this.state.upload_status === 1 ? (
                    <div className="">Please wait...</div>
                  ) : (
                    <div className=""></div>
                  )}

                  {this.state.upload_status === 2 ? (
                    <div className="">File uploaded</div>
                  ) : (
                    <div className=""></div>
                  )}
                </div>
                <div className="col-lg-6 col-md-12">
                  <label htmlFor="">Link to a Goal</label>
                  <div className="form-group">
                    <select
                      name="customSearch"
                      className="custom-search-select form-control"
                      onChange={this.handleChange}
                      id="sel1"
                      value={this.state.task_goalid}
                    >
                      <option>Select Goal</option>
                      {goalOptions}
                    </select>
                  </div>
                  {/*                 
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.task_goalid}
                    placeholder="goalid"
                    onChange={this.onChangeTaskGoalId}
                  />
                </div> */}
                </div>
              </div>
            </div>
            <div className="form-group">
              <button
                type="submit"
                disabled={
                  this.state.todo_title.length < 2 ||
                  this.state.task_duedate === ""
                }
                className="btn btn-primary"
              >
                Create Task
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
