import React, { Component } from "react";
import { Link } from "react-router-dom";

import logo from "../../logo.png";

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTop: true,
    };
    this.onScroll = this.onScroll.bind(this);
  }

  closeNav = () => {
    document.getElementById("navbarCollapse").className = document
      .getElementById("navbarCollapse")
      .className.replace("show", "");
    console.log("close nav");
  };

  componentDidMount() {
    document.addEventListener("scroll", () => {
      const isTop = window.scrollY < 100;
      if (isTop !== this.state.isTop) {
        this.onScroll(isTop);
      }
    });
  }

  onScroll(isTop) {
    this.setState({ isTop });
  }

  render() {
    return (
      <div>
        <nav
          className={
            this.state.isTop &&
            (this.props.page == "home" || this.props.page == "coming")
              ? "navbar navbar-expand-lg  fixed-top  "
              : this.props.page === "home" || this.props.page == "coming"
              ? "navbar navbar-expand-lg  fixed-top  navbar-dark opaque"
              : "navbar navbar-expand-lg  fixed-top  navbar-dark opaque-dark"
          }
        >
          <div className="navsub">
            <div style={{ display: "inline" }}>
              <a className="navbar-brand-img " href="/">
                <img
                  className="navbar-brand-img-inner"
                  src={logo}
                  //src="/images/logo/logo-t.png"
                  alt="bealyo.com"
                />
              </a>
            </div>
            {/** 
            <div style={{ display: "inline" }}>
              <Link to="/" className="navbar-brand">
                BEALYO
              </Link>
            </div>
            */}
            <button
              className="navbar-toggler navbar-light bg-lignt"
              type="button"
              data-toggle="collapse"
              data-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>

            <div
              className="navbar-collapse collapse mobile-top-menu  "
              id="navbarCollapse"
            >
              <ul className="navbar-nav mr-auto ">
                <li className="navbar-item">
                  <div className="mobile-menu">
                    <img
                      src="/images/icons/menu-home.png"
                      style={{ width: 14 }}
                    />
                  </div>
                  <div>
                    <Link
                      to="/home"
                      className="nav-link"
                      onClick={this.closeNav}
                    >
                      Home <span className="sr-only">(current)</span>
                    </Link>
                  </div>
                </li>
                <li className="navbar-item">
                  <div className="mobile-menu">
                    <img
                      src="/images/icons/menu-task.png"
                      style={{ width: 14 }}
                    />
                  </div>
                  <div>
                    <Link
                      to="/about"
                      className="nav-link"
                      onClick={this.closeNav}
                    >
                      How It Works? <span className="sr-only">(current)</span>
                    </Link>
                  </div>
                </li>
                {/* <li className="navbar-item">
                  <div className="mobile-menu">
                    <img
                      src="/images/icons/menu-goal.png"
                      style={{ width: 14 }}
                    />
                  </div>
                  <div>
                    <Link
                      to="/goals"
                      className="nav-link"
                      onClick={this.closeNav}
                    >
                      Goals <span className="sr-only">(current)</span>
                    </Link>
                  </div>
                </li>
                <li className="navbar-item">
                  <div className="mobile-menu">
                    <img
                      src="/images/icons/menu-goal.png"
                      style={{ width: 14 }}
                    />
                  </div>
                  <div>
                    <Link to="/" className="nav-link" onClick={this.closeNav}>
                      Tasks <span className="sr-only">(current)</span>
                    </Link>
                  </div>
                </li> */}

                {/* <li className="navbar-item">
                  <Link
                    to="/lists"
                    className="nav-link"
                    onClick={this.closeNav}
                  >
                    Lists <span className="sr-only">(current)</span>
                  </Link>
                </li> */}

                {/* <li className="navbar-item">
                  <Link
                    to="/register"
                    className=" nav-link"
                    onClick={this.closeNav}
                  >
                    Sign Up
                  </Link>
                </li> */}
                <li className="navbar-item">
                  <div className="mobile-menu">
                    <img
                      src="/images/icons/menu-goal.png"
                      style={{ width: 14 }}
                    />
                  </div>
                  <div>
                    <Link
                      to="/Login"
                      className=" nav-link"
                      onClick={this.closeNav}
                    >
                      Login <span className="sr-only">(current)</span>
                    </Link>
                  </div>
                </li>

                <li className="navbar-item">
                  <div className="mobile-menu">
                    <img
                      src="/images/icons/menu-goal.png"
                      style={{ width: 14 }}
                    />
                  </div>
                  <div>
                    <Link
                      to="/Register"
                      className=" nav-link"
                      onClick={this.closeNav}
                    >
                      <b>Sign Up</b> <span className="sr-only">(current)</span>
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}
export default Navbar;
