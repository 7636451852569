import React, { Component } from "react";
import { Link } from "react-router-dom";

import OnthewayComponent from "../ontheway/ontheway.component"; // Adjust the path as necessary

class Coming extends Component {
  constructor(props) {
    super(props);

    //this.state = {
    // isTop: true,
    // };
    //this.onScroll = this.onScroll.bind(this);
  }

  state = {
    imageLoaded: false,
  };

  componentDidMount() {
    const img = new Image();
    img.src = process.env.PUBLIC_URL + "/images/bg/10.jpg";
    img.onload = () => {
      this.setState({ imageLoaded: true });
    };
    // document.addEventListener("scroll", () => {
    //   const isTop = window.scrollY < 100;
    //   if (isTop !== this.state.isTop) {
    //     this.onScroll(isTop);
    //   }
    // });
  }

  onScroll(isTop) {
    //this.setState({ isTop });
  }

  render() {
    return (
      <div className=" container-fluid ">
        <div
          className="homepage-bg-color"
          style={{
            //paddingTop: 120,
            color: "#ffffff",
            fontWeight: "600",
            textShadow: "1px 1px #000000",
            marginRight: -15,
            marginLeft: -15,
            backgroundImage: `  linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 70%),url(${
              process.env.PUBLIC_URL + "/images/bg/10.jpg"
            })`,
            //background: "linear-gradient(to top, #000000, #434343)",
          }}
        >
          <div className="container hm-main">
            <div style={{ marginBottom: "16px" }}>
              <img
                src="/images/logo/fulllogo-1.png"
                style={{ width: "120px" }}
                alt=""
              />
            </div>

            <div>
              <OnthewayComponent />
            </div>
          </div>
          <div></div>
        </div>
      </div>
    );
  }
}

export default Coming;
