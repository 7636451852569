import React, { Component } from "react";
import axios from "axios";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import PNavbar from "../private-route/Nav";
import TodoList from "../tasks/todo-list.componenet";
import Breadcrumb from "react-bootstrap/Breadcrumb";

export default class Goal extends Component {
  constructor(props) {
    super(props);

    this.onChangeGoalTitle = this.onChangeGoalTitle.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.getTaskValues = this.getTaskValues.bind(this);
    this.forceUpdateHandler = this.forceUpdateHandler.bind(this);

    this.state = {
      goal_title: "",
      _id: "",
      goaldata: "",
      tasksValue: 0,
      tasksReported: 0,
      TasksGoalPercentage: "0",
      sid: ""
    };

    this.getGoalData();
  }

  //#region  getGoalData
  getGoalData() {
    axios
      .get(
        process.env.REACT_APP_API_URL + "goals/" + this.props.match.params.id
      )
      .then(response => {
        var min = 1;
        var max = 100;
        var rand = min + Math.random() * (max - min);

        const goaldataval = {
          title: "Tasks",
          id: response.data._id,
          skin: "1",
          rand: rand
        };

        //console.log("goaldataval");
        console.log("response.data");
        console.log(response.data);
        this.setState({
          goal_title: response.data.goal_title,
          _id: response.data._id,
          goaldata: goaldataval
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  componentDidMount() {
    this.getGoalData();
  }
  //#endregion

  forceUpdateHandler() {
    this.forceUpdate();
  }

  onChangeGoalTitle(e) {
    this.setState({
      goal_title: e.target.value
    });
  }

  onSubmit(e) {
    e.preventDefault();

    const obj = {
      goal_title: this.state.goal_title
    };

    axios
      .post(
        process.env.REACT_APP_API_URL +
          "goals/update/" +
          this.props.match.params.id,
        obj
      )
      .then(res => console.log(res.data));

    this.props.history.push("/goals");
  }

  onDelete() {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          "goals/remove/" +
          this.props.match.params.id
      )
      .then(console.log("Deleted"))
      .catch(err => console.log(err));

    this.props.history.push("/goals");
  }

  componentDidUpdate(prevProps, prevState) {
    // //Typical usage, don't forget to compare the props
    // console.log("prevProps");
    // console.log(prevProps);
    // console.log("prevState");
    // console.log(prevState);
    // console.log("this.props");
    // console.log(this.props);
    // console.log("this.state.refreshTaskList");
    // console.log(this.state.refreshTaskList);

    if (
      this.props.match.params.id !== prevState._id ||
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      //this.getGoalData();
      //console.log("GetDATA");
    }
  }

  getTaskValues(value, reported) {
    // var TtasksValue = this.state.tasksValue + value;

    this.setState({
      tasksValue: value
    });

    // var TtasksReported = this.state.tasksReported + reported;

    this.setState({
      tasksReported: reported
    });
    var percentage = 0;
    if (
      value != "" &&
      value !== undefined &&
      value !== "NaN" &&
      reported != "" &&
      reported !== undefined &&
      reported !== "NaN"
    ) {
      percentage = 100 * (reported / value);
      percentage = Number(percentage.toFixed(1));
    }

    // console.log("value");
    // console.log(value);
    // console.log("reported");
    // console.log(reported);

    this.setState({
      TasksGoalPercentage: percentage
    });
  }

  render() {
    return (
      <div className="container">
        <PNavbar />
        <div className="row-b">
          <div className="container container-fluid ">
            {/* <Breadcrumb>
            <Breadcrumb.Item href="/dash">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>
              {this.state.goal_title != "" ? this.state.goal_title : "Goal"}
            </Breadcrumb.Item>
          </Breadcrumb> */}

            {this.state.goal_title != "" ? (
              <div className="goal-header-panel-main">
                <div className="" style={{ height: 130 }}>
                  <div className="goal-title-div-main">
                    <div className="row">
                      <div className="col-9 col-md-11">
                        <span className="goal-title">
                          <a> {this.state.goal_title}</a>
                        </span>
                      </div>
                      <div className="col-3  col-md-1">
                        <div style={{ marginRight: 9 }}>
                          <CircularProgressbar
                            value={this.state.TasksGoalPercentage}
                            text={`${this.state.TasksGoalPercentage}%`}
                            circleRatio={0.75}
                            styles={buildStyles({
                              rotation: 1 / 2 + 1 / 8,
                              strokeLinecap: "butt",
                              trailColor: "#ffffff",
                              textColor: "#fff",
                              pathColor: "#fff",
                              trailColor: "#343a40"
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
        <div style={{ marginTop: 65 }}>
          <TodoList
            goaldata={this.state.goaldata}
            gid={this.props.match.params.id}
            action={this.getTaskValues}
          />
        </div>
      </div>
    );
  }
}
