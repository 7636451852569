import React, { Component } from "react";
import axios from "axios";
import { Route, withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { string } from "prop-types";

class SuggestedGoals extends Component {
  //#region constructor
  constructor(props) {
    super(props);

    this.onSetGoal = this.onSetGoal.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeGoalTitle = this.onChangeGoalTitle.bind(this);
    this.onChangeGoalDesc = this.onChangeGoalDesc.bind(this);
    this.onChangeGoalPeriod = this.onChangeGoalPeriod.bind(this);

    this.state = {
      goal_title: "",
      goal_desc: "",
      goal_catid: "",
      goal_period: "44"
    };
  }
  //#endregion

  //#region onSetGoal
  onSetGoal(e, t) {
    console.log(t);
    //let val = e.target.value;
    this.setState({
      goal_title: t
    });
  }
  //#endregion

  //#region onResetSuggested
  onResetSuggested() {
    this.setState({
      goal_title: ""
    });
  }
  //#endregion

  //#region onChangeGoalTitle
  onChangeGoalTitle(e) {
    console.log("onChangeGoalTitle");
    this.setState({
      goal_title: e.target.value
    });
  }
  //#endregion

  //#region onChangeGoalTitle
  onChangeGoalDesc(e) {
    console.log("onChangeGoalDesc");
    this.setState({
      goal_desc: e.target.value
    });
  }
  //#endregion

  //#region onChangeGoalPeriod
  onChangeGoalPeriod(e) {
    let valu = e.target.value;

    if (!Number(valu)) {
      return;
    }

    this.setState({ goal_period: e.target.value });
  }
  //#endregion

  //#region onSubmit
  onSubmit(e) {
    e.preventDefault();

    console.log("Form submitted:");
    console.log("Goal Title:" + this.state.goal_title);

    const newGoal = {
      goal_title: this.state.goal_title,
      goal_desc: this.state.goal_desc,
      goal_catid: this.props.id,
      goal_period: this.state.goal_period
    };

    axios
      .post(process.env.REACT_APP_API_URL + "goals/add", newGoal)
      .then(res => console.log(res.data));

    this.setState({
      list_title: ""
    });

    this.props.history.push("/goals");
  }
  //#endregion

  render() {
    return (
      <div>
        {this.state.goal_title !== "" || this.props.id === "8" ? (
          <span></span>
        ) : (
          <div>
            <h5>Suggested Goals {this.state.goal_title}</h5>
            <div className="shadow-sm p-3 mb-2 bg-white rounded">
              <div style={{ marginBottom: 10 }}>
                {this.state.goal_title === "" &&
                (this.props.id === "1" ||
                  this.props.id === "2" ||
                  this.props.id === "3" ||
                  this.props.id === "4" ||
                  this.props.id === "5" ||
                  this.props.id === "6" ||
                  this.props.id === "7") ? (
                  <div>
                    <span>
                      You can choose from one of our suggested destinations or
                      create a custom goal.
                    </span>
                  </div>
                ) : (
                  <span></span>
                )}
                {this.state.goal_title === "" && this.props.id === "1" ? (
                  <div
                    className="row"
                    style={{ marginLeft: -3, paddingRight: 12 }}
                  >
                    <div className="shadow-sm col-sm-2  bg-blue rounded goal-cell">
                      <div onClick={() => this.onSetGoal("11", "Eat breakfas")}>
                        Eat breakfast
                      </div>
                    </div>
                    <div className="shadow-sm col-sm-2 bg-blue rounded goal-cell">
                      <div onClick={() => this.onSetGoal("12", "No Fast Food")}>
                        No Fast Food
                      </div>
                    </div>
                    <div className="shadow-sm col-sm-2 bg-blue rounded goal-cell">
                      <div onClick={() => this.onSetGoal("13", "Lose Weight")}>
                        Lose Weight
                      </div>
                    </div>
                    <div className="shadow-sm col-sm-2 bg-blue rounded goal-cell">
                      <div
                        onClick={() =>
                          this.onSetGoal("14", "Eat Fruits & Vegd")
                        }
                      >
                        Eat Fruits & Veg
                      </div>
                    </div>

                    <div className="shadow-sm col-sm-2 bg-blue rounded goal-cell">
                      <div onClick={() => this.onSetGoal("15", "Eat Fish")}>
                        Eat Fish
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {this.state.goal_title === "" && this.props.id === "6" ? (
                  <div
                    className="row"
                    style={{ marginLeft: -3, paddingRight: 12 }}
                  >
                    <div className="shadow-sm col-sm-2  bg-blue rounded goal-cell">
                      <div
                        onClick={() => this.onSetGoal("61", "Dental Checkup")}
                      >
                        Dental Checkup
                      </div>
                    </div>
                    <div className="shadow-sm col-sm-2 bg-blue rounded goal-cell">
                      <div onClick={() => this.onSetGoal("62", "Meditate")}>
                        Meditate
                      </div>
                    </div>
                    <div className="shadow-sm col-sm-2 bg-blue rounded goal-cell">
                      <div
                        onClick={() => this.onSetGoal("63", "Health Checkup")}
                      >
                        Health Checkup
                      </div>
                    </div>
                    <div className="shadow-sm col-sm-2 bg-blue rounded goal-cell">
                      <div
                        onClick={() => this.onSetGoal("64", "Get Enough Sleep")}
                      >
                        Get Enough Sleep
                      </div>
                    </div>
                    <div className="shadow-sm col-sm-2 bg-blue rounded goal-cell">
                      <div
                        onClick={() => this.onSetGoal("65", "Go on Vacation")}
                      >
                        Go on Vacation
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {this.state.goal_title === "" && this.props.id === "5" ? (
                  <div
                    className="row"
                    style={{ marginLeft: -3, paddingRight: 12 }}
                  >
                    <div className="shadow-sm col-sm-2  bg-blue rounded goal-cell">
                      <div onClick={() => this.onSetGoal("51", "Stop Smoking")}>
                        Stop Smoking
                      </div>
                    </div>
                    <div className="shadow-sm col-sm-2 bg-blue rounded goal-cell">
                      <div
                        onClick={() =>
                          this.onSetGoal("52", "Stop Watching too Much TV")
                        }
                      >
                        Stop Watching too Much TV
                      </div>
                    </div>
                    <div className="shadow-sm col-sm-2 bg-blue rounded goal-cell">
                      <div
                        onClick={() =>
                          this.onSetGoal("53", "Stop Overusing Painkillers")
                        }
                      >
                        Stop Overusing Painkillers
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {this.state.goal_title === "" && this.props.id === "7" ? (
                  <div
                    className="row"
                    style={{ marginLeft: -3, paddingRight: 12 }}
                  >
                    <div className="shadow-sm col-sm-2  bg-blue rounded goal-cell">
                      <div onClick={() => this.onSetGoal("71", "Save Money")}>
                        Save Money
                      </div>
                    </div>
                    <div className="shadow-sm col-sm-2 bg-blue rounded goal-cell">
                      <div
                        onClick={() => this.onSetGoal("72", "Get Out of Debt")}
                      >
                        Get Out of Debt
                      </div>
                    </div>
                    <div className="shadow-sm col-sm-2 bg-blue rounded goal-cell">
                      <div
                        onClick={() =>
                          this.onSetGoal("73", "Pay off student loans")
                        }
                      >
                        Pay off student loans
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {this.state.goal_title === "" && this.props.id === "2" ? (
                  <div
                    className="row"
                    style={{ marginLeft: -3, paddingRight: 12 }}
                  >
                    <div className="shadow-sm col-sm-2  bg-blue rounded goal-cell">
                      <div onClick={() => this.onSetGoal("21", "Read a book")}>
                        Read a book
                      </div>
                    </div>
                    <div className="shadow-sm col-sm-2 bg-blue rounded goal-cell">
                      <div onClick={() => this.onSetGoal("22", "Study")}>
                        Study
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {this.state.goal_title === "" && this.props.id === "3" ? (
                  <div
                    className="row"
                    style={{ marginLeft: -3, paddingRight: 12 }}
                  >
                    <div className="shadow-sm col-sm-2  bg-blue rounded goal-cell">
                      <div
                        onClick={() => this.onSetGoal("31", "Start Running")}
                      >
                        Start Running
                      </div>
                    </div>
                    <div className="shadow-sm col-sm-2 bg-blue rounded goal-cell">
                      <div
                        onClick={() => this.onSetGoal("32", "Start Walking")}
                      >
                        Start Walking
                      </div>
                    </div>
                    <div className="shadow-sm col-sm-2 bg-blue rounded goal-cell">
                      <div
                        onClick={() => this.onSetGoal("33", "Play Basketball")}
                      >
                        Play Basketball
                      </div>
                    </div>
                    <div className="shadow-sm col-sm-2 bg-blue rounded goal-cell">
                      <div onClick={() => this.onSetGoal("34", "Play Soccer")}>
                        Play Soccer
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {this.state.goal_title === "" && this.props.id === "4" ? (
                  <div
                    className="row"
                    style={{ marginLeft: -3, paddingRight: 12 }}
                  >
                    <div className="shadow-sm col-sm-2  bg-blue rounded goal-cell">
                      <div onClick={() => this.onSetGoal("41", "Networking")}>
                        Networking
                      </div>
                    </div>
                    <div className="shadow-sm col-sm-2 bg-blue rounded goal-cell">
                      <div onClick={() => this.onSetGoal("42", "New Job")}>
                        New Job
                      </div>
                    </div>
                    <div className="shadow-sm col-sm-2 bg-blue rounded goal-cell">
                      <div onClick={() => this.onSetGoal("43", "Work Hard")}>
                        Work Hard
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <br />
              </div>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-6">
            <h5>Create a custom Goal</h5>
          </div>
          <div className="col-6" style={{ textAlign: "right" }}>
            {this.state.goal_title != "" && this.props.id !== "8" ? (
              <Link to="" onClick={() => this.onResetSuggested()}>
                View Suggested Goals
              </Link>
            ) : (
              <span></span>
            )}
          </div>
        </div>

        <div className="shadow-sm p-3 mb-2 bg-white rounded">
          <form onSubmit={this.onSubmit}>
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <label htmlFor="">Goal Name</label>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.goal_title}
                    placeholder="Goal Title"
                    onChange={this.onChangeGoalTitle}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <label htmlFor="">Time Period (days)</label>
                <div className="form-group">
                  <input
                    type="Number"
                    className="form-control"
                    value={this.state.goal_period}
                    placeholder="Time Period"
                    onChange={this.onChangeGoalPeriod}
                  />
                  <span className="input-explain">
                    The recommended time period for completing a goal (44 days).
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <label htmlFor="">Goal Description</label>
                  <textarea
                    className="form-control"
                    onChange={this.onChangeGoalDesc}
                    value={this.state.goal_desc}
                    placeholder="Task description"
                    id="exampleFormControlTextarea1"
                    rows="3"
                  ></textarea>
                </div>
              </div>
              <div className="col-lg-6 col-md-12"></div>
            </div>

            <div className="form-group" style={{ marginBottom: 0 }}>
              <button
                type="button"
                type="submit"
                disabled={!this.state.goal_title}
                className="btn btn-primary"
              >
                Add Goal
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(SuggestedGoals);
