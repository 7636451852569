import React, { Component } from "react";
import Navbar from "../layout/Navbar";
import Breadcrumb from "react-bootstrap/Breadcrumb";

class ContactUs extends Component {
  constructor(props) {
    super(props);

    this.onChangeContactFname = this.onChangeContactFname.bind(this);
    this.onChangeContactIssue = this.onChangeContactIssue.bind(this);
    this.onChangeContactDescription = this.onChangeContactDescription.bind(
      this
    );
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      contactFname: "",
      contactIssue: "",
      contactDescription: ""
    };
  }

  //#region onChangeContactFname
  onChangeContactFname(e) {
    console.log("onChangeContactFname");
    this.setState({
      contactFname: e.target.value
    });
  }
  //#endregion

  //#region onChangeContactIssue
  onChangeContactIssue(e) {
    console.log("onChangeContactIssue");
    this.setState({
      contactIssue: e.target.value
    });
  }
  //#endregion

  //#region onChangeContactDescription
  onChangeContactDescription(e) {
    console.log("onChangecontactDescription");
    this.setState({
      contactDescription: e.target.value
    });
  }
  //#endregion

  //#region onSubmit
  onSubmit(e) {
    e.preventDefault();

    const newContact = {
      contactFname: this.state.contactFname
    };

    // axios
    //   .post(process.env.REACT_APP_API_URL + "todos/add", newTodo)
    //   .then(res => console.log(res.data));

    // this.setState({
    //   todo_description: "",
    //   todo_title: "",
    //   todo_responsible: "",
    //   todo_priority: "",
    //   todo_completed: false,
    //   todo_image_url: "",
    //   task_value: 0,
    //   task_duedate: "",
    //   task_goalid: ""
    // });
    // if (this.state.task_goalid !== "" && this.state.task_goalid !== undefined) {
    //   this.props.history.push("/goal/" + this.state.task_goalid);
    // } else {
    //   this.props.history.push("/");
    // }
  }
  //#endregion

  render() {
    return (
      <div className="container">
        <form onSubmit={this.onSubmit}>
          <Navbar />
          <div>
            <Breadcrumb>
              <Breadcrumb.Item href="/dash">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Contact Us</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div>
            <h1 className="h1-home-header-main">Contact Us</h1>
          </div>
          <div className="shadow-sm p-3 mb-2 bg-white rounded">
            {/* <p className="lead">Contact Us</p> */}

            <div className="form-group">
              {/* <label for="">Full Name</label> */}
              <input
                type="text"
                className="form-control"
                value={this.state.contactFname}
                placeholder="Full Name"
                onChange={this.onChangeContactFname}
              />
            </div>
            <div className="form-group">
              {/* <label for="">Issue</label> */}
              <input
                type="text"
                className="form-control"
                value={this.state.contactIssue}
                placeholder="Issue"
                onChange={this.onChangeContactIssue}
              />
            </div>
            <div className="form-group">
              <textarea
                className="form-control"
                onChange={this.onChangeContactDescription}
                value={this.state.contactDescription}
                placeholder="Short description"
                id="exampleFormControlTextarea1"
                rows="3"
              ></textarea>
            </div>
          </div>
          <div className="form-group">
            <button
              type="submit"
              disabled={
                this.state.contactFname.length < 2 ||
                this.state.contactIssue === ""
              }
              className="btn btn-primary"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default ContactUs;
