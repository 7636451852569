import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navbar from "../layout/Navbar";

class PHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTop: true,
    };
    this.onScroll = this.onScroll.bind(this);
  }

  componentDidMount() {
    // document.addEventListener("scroll", () => {
    //   const isTop = window.scrollY < 100;
    //   if (isTop !== this.state.isTop) {
    //     this.onScroll(isTop);
    //   }
    // });
  }

  onScroll(isTop) {
    this.setState({ isTop });
  }

  render() {
    return (
      <div className=" container-fluid ">
        <Navbar page="home" />
        <div
          className="homepage-pic"
          style={{
            paddingTop: 120,
            color: "#ffffff",
            fontWeight: "600",
            textShadow: "1px 1px #000000",
            marginRight: -15,
            marginLeft: -15,
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "/images/bg/2.jpg"
            })`,
          }}
        >
          <div className="container hm-main">
            <span> Become the best version of yourself</span>
            <p style={{ paddingTop: 20 }}>
              <Link to="/register" className="btn btn-primary btn-lg">
                Get Started! - It's Free
              </Link>
            </p>
          </div>
        </div>
        <div className="row row-w">
          <div className="container container-fluid ">
            <div className="row">
              <div className="col-xs-12 col-md-4 hm-column ">
                <img src="/images/task.png" alt="" />
                <p>Effective</p>
                <div className="hp-box">
                  <span>Our smart AI algorithm is here to help you </span>
                </div>
              </div>
              <div className="col-xs-12 col-md-4 hm-column">
                <img src="/images/days_left.jpg" alt="" />
                <p>Simple</p>
                <div className="hp-box">
                  <span>Create your life goals and tasks in seconds </span>
                </div>
              </div>
              <div className="col-xs-12 col-md-4 hm-column">
                <img src="/images/goal.png" alt="" />
                <p>Empowering</p>
                <div className="hp-box">
                  <span>
                    After achivinings each goal you'll feel Invincible
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row row-g">
          <div className="container container-fluid ">
            <div
              style={{ marginTop: 10, textAlign: "center", paddingBottom: 40 }}
            >
              <h1 className="h1-home-header-home">Become a Goal Achiever</h1>
            </div>
            <div className="row">
              <div className="col-xs-12 col-md-4 hm-column ">
                <div className="shadow-sm p-3 mb-2 bg-white rounded">
                  <img
                    src="/images/faces/face2.png"
                    className="hp-facebox"
                    alt=""
                  />
                  <p>Billy</p>
                  <div className="hp-box">
                    <div className="row" style={{ paddingLeft: 70 }}>
                      <div className="col-2 col-md-2">
                        <img
                          src="/images/icons/trophy.png"
                          className="hp-trophy"
                          alt=""
                        />{" "}
                      </div>
                      <div
                        className="col-10 col-md-10"
                        style={{ textAlign: "left", paddingTop: 1 }}
                      >
                        4,600 Points
                      </div>
                    </div>
                  </div>
                  <div className="hp-box">
                    <div className="row" style={{ paddingLeft: 70 }}>
                      <div className="col-2 col-md-2">
                        <img
                          src="/images/goal.png"
                          className="hp-trophy"
                          alt=""
                        />
                      </div>
                      <div
                        className="col-10 col-md-10"
                        style={{ textAlign: "left", paddingTop: 1 }}
                      >
                        2 Goals
                      </div>
                    </div>
                  </div>
                  <div className="hp-box">
                    <div className="row" style={{ paddingLeft: 70 }}>
                      <div className="col-2 col-md-2">
                        <img
                          src="/images/task.png"
                          className="hp-trophy"
                          alt=""
                        />
                      </div>
                      <div
                        className="col-10 col-md-10"
                        style={{ textAlign: "left", paddingTop: 1 }}
                      >
                        27 Tasks
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-4 hm-column">
                <div className="shadow-sm p-3 mb-2 bg-white rounded">
                  <img
                    src="/images/faces/face3.png"
                    className="hp-facebox"
                    alt=""
                  />
                  <p>Janet</p>
                  <div className="hp-box">
                    <div className="row" style={{ paddingLeft: 70 }}>
                      <div className="col-2 col-md-2">
                        <img
                          src="/images/icons/trophy.png"
                          className="hp-trophy"
                          alt=""
                        />{" "}
                      </div>
                      <div
                        className="col-10 col-md-10"
                        style={{ textAlign: "left", paddingTop: 1 }}
                      >
                        2,300 Points
                      </div>
                    </div>
                  </div>
                  <div className="hp-box">
                    <div className="row" style={{ paddingLeft: 70 }}>
                      <div className="col-2 col-md-2">
                        <img
                          src="/images/goal.png"
                          className="hp-trophy"
                          alt=""
                        />
                      </div>
                      <div
                        className="col-10 col-md-10"
                        style={{ textAlign: "left", paddingTop: 1 }}
                      >
                        3 Goals
                      </div>
                    </div>
                  </div>
                  <div className="hp-box">
                    <div className="row" style={{ paddingLeft: 70 }}>
                      <div className="col-2 col-md-2">
                        <img
                          src="/images/task.png"
                          className="hp-trophy"
                          alt=""
                        />
                      </div>
                      <div
                        className="col-10 col-md-10"
                        style={{ textAlign: "left", paddingTop: 1 }}
                      >
                        13 Tasks
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-4 hm-column">
                <div className="shadow-sm p-3 mb-2 bg-white rounded">
                  <img
                    src="/images/faces/face4.png"
                    className="hp-facebox"
                    alt=""
                  />
                  <p>Shawn</p>
                  <div className="hp-box">
                    <div className="row" style={{ paddingLeft: 70 }}>
                      <div className="col-2 col-md-2">
                        <img
                          src="/images/icons/trophy.png"
                          className="hp-trophy"
                          alt=""
                        />{" "}
                      </div>
                      <div
                        className="col-10 col-md-10"
                        style={{ textAlign: "left", paddingTop: 1 }}
                      >
                        7,420 Points
                      </div>
                    </div>
                  </div>
                  <div className="hp-box">
                    <div className="row" style={{ paddingLeft: 70 }}>
                      <div className="col-2 col-md-2">
                        <img
                          src="/images/goal.png"
                          className="hp-trophy"
                          alt=""
                        />
                      </div>
                      <div
                        className="col-10 col-md-10"
                        style={{ textAlign: "left", paddingTop: 1 }}
                      >
                        5 Goals
                      </div>
                    </div>
                  </div>
                  <div className="hp-box">
                    <div className="row" style={{ paddingLeft: 70 }}>
                      <div className="col-2 col-md-2">
                        <img
                          src="/images/task.png"
                          className="hp-trophy"
                          alt=""
                        />
                      </div>
                      <div
                        className="col-10 col-md-10"
                        style={{ textAlign: "left", paddingTop: 1 }}
                      >
                        34 Tasks
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hp-viewmore">
              Join millions of people to already using bealyo by organizing your
              life goals and make the most of your life <br />
              <br />
              <Link to="/login">Join Now! - It's free</Link>
            </div>

            {/* <div className="shadow-sm p-3 mb-2 bg-white rounded "> */}
            {/* <div className="">
              <p className="lead">               
                Life can feel overwhelming, but it doesn’t have to. bealyo lets
                you keep track of everything in one place, so you can get it all
                done and enjoy more peace of mind along the way.
              </p>
              <p className="lead">
                Join millions of people to already using bealyo, organize
                to-dos, and make the most of your life.
              </p>

              <Link to="/register" className="btn btn-primary btn-lg">
                Get Started! - It's Free
              </Link>
            </div> */}
          </div>
        </div>

        <div className="row row-w">
          <div className="container container-fluid ">
            <div
              style={{ marginTop: 10, textAlign: "center", paddingBottom: 40 }}
            >
              <h1 className="h1-home-header-home">
                Bealyo helps you to achihve your Goals
              </h1>
            </div>
            <div className="row">
              <div className="col-xs-12 col-md-3 hm-column-goals ">
                <div className="shadow-sm p-3 mb-2 bg-white rounded">
                  <span className="hp-goalsbox">Become an athletic</span>
                </div>
              </div>
              <div className="col-xs-12 col-md-3 hm-column-goals ">
                <div className="shadow-sm p-3 mb-2 bg-white rounded">
                  <span className="hp-goalsbox">Lose some weight</span>
                </div>
              </div>
              <div className="col-xs-12 col-md-3 hm-column-goals ">
                <div className="shadow-sm p-3 mb-2 bg-white rounded">
                  <span className="hp-goalsbox">Earn more money</span>
                </div>
              </div>
              <div className="col-xs-12 col-md-3 hm-column-goals ">
                <div className="shadow-sm p-3 mb-2 bg-white rounded">
                  <span className="hp-goalsbox">Get rid bad habits</span>
                </div>
              </div>
            </div>
            <div className="hp-viewmore">
              <Link to="/login">
                <b>Join Now!</b> - and take control over your life{" "}
              </Link>
            </div>

            {/* <div className="shadow-sm p-3 mb-2 bg-white rounded "> */}
            {/* <div className="">
              <p className="lead">               
                Life can feel overwhelming, but it doesn’t have to. bealyo lets
                you keep track of everything in one place, so you can get it all
                done and enjoy more peace of mind along the way.
              </p>
              <p className="lead">
                Join millions of people to already using bealyo, organize
                to-dos, and make the most of your life.
              </p>

              <Link to="/register" className="btn btn-primary btn-lg">
                Get Started! - It's Free
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default PHome;
