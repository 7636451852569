import React, { Component } from "react";
import Navbar from "../layout/Navbar";
import Breadcrumb from "react-bootstrap/Breadcrumb";

class QA extends Component {
  render() {
    return (
      <div className="container">
        <Navbar />
        <div>
          <Breadcrumb>
            <Breadcrumb.Item href="/dash">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Q&A</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div>
          <h1 className="h1-home-header-main">Q&A</h1>
        </div>
        <div className="shadow-sm p-3 mb-2 bg-white rounded">
          <b>Q: What is Bealyo?</b>
          <p>
            A: When registering for Bealyo we ask for information such as your
            name and email address. Your information is only used internally and
            won't be shared with others.
          </p>
        </div>
      </div>
    );
  }
}

export default QA;
