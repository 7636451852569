import React, { Component } from "react";
import axios from "axios";
import PNavbar from "../private-route/Nav";
import Breadcrumb from "react-bootstrap/Breadcrumb";

export default class CreateList extends Component {
  constructor(props) {
    super(props);

    this.onChangeListTitle = this.onChangeListTitle.bind(this);
    // this.onChangeTodoResponsiblen = this.onChangeTodoResponsiblen.bind(this);
    // this.onChangeTodoPriority = this.onChangeTodoPriority.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      list_title: ""
    };
  }

  onChangeListTitle(e) {
    console.log("onChangeListTitle");
    this.setState({
      list_title: e.target.value
    });
  }

  //   onChangeTodoResponsiblen(e) {
  //     console.log("onChangeTodoResponsiblen");
  //     this.setState({
  //       todo_responsible: e.target.value
  //     });
  //   }
  //   onChangeTodoPriority(e) {
  //     console.log("onChangeTodoPriority");
  //     this.setState({
  //       todo_priority: e.target.value
  //     });
  //   }
  onSubmit(e) {
    e.preventDefault();

    console.log("Form submitted:");
    console.log("List Title:" + this.state.list_title);

    const newList = {
      list_title: this.state.list_title,
      u_id: ""
    };

    let jwtToken = localStorage.getItem("jwtToken").replace("Bearer ", "");
    console.log("jwtToken:" + jwtToken);

    const headers = {
      "x-access-token": `${jwtToken}`
    };

    axios
      .post(process.env.REACT_APP_API_URL + "lists/add", newList)
      .then(res => console.log(res.data));

    this.setState({
      list_title: ""
    });

    this.props.history.push("/lists");
  }

  render() {
    return (
      <div className="container">
        <PNavbar />
        <div>
          <Breadcrumb>
            <Breadcrumb.Item href="/dash">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/lists">Lists</Breadcrumb.Item>
            <Breadcrumb.Item active>Add List</Breadcrumb.Item>
          </Breadcrumb>

          <h3>Add List</h3>
          <form onSubmit={this.onSubmit}>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                value={this.state.list_title}
                placeholder="List Title"
                onChange={this.onChangeListTitle}
              />
            </div>
            {/* <div className="form-group">
              <label>Responsible:</label>
              <input
                type="text"
                className="form-control"
                value={this.state.todo_responsible}
                onChange={this.onChangeTodoResponsiblen}
              />
            </div>
            <div className="form-group">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="priorityOptions"
                  id="priorityLow"
                  value="Low"
                  checked={this.state.todo_priority === "Low"}
                  onChange={this.onChangeTodoPriority}
                />
                <label className="form-check-label">Low</label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="priorityOptions"
                  id="priorityMedium"
                  value="Medium"
                  checked={this.state.todo_priority === "Medium"}
                  onChange={this.onChangeTodoPriority}
                />
                <label className="form-check-label">Medium</label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="priorityOptions"
                  id="priorityHigh"
                  value="High"
                  checked={this.state.todo_priority === "High"}
                  onChange={this.onChangeTodoPriority}
                />
                <label className="form-check-label">High</label>
              </div>
            </div> */}
            <div className="form-group">
              <input
                type="submit"
                value="Add List"
                className="btn btn-primary"
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}
